import { makeStyles } from "@mui/styles";
import bg from "../../../assets/images/join-better-bg.svg";
import img from "../../../assets/images/join-better-img.webp";
import { colors } from "../../../styles/colors";

export const useStyles = makeStyles((theme) => ({
  container: {
    backgroundImage: `url(${bg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    padding: "96px 0px",
    "& .wrapper": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      background: "#fff",
      borderRadius: "20px",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        justifyContent: "center",
      },

      "& .left": {
        padding: "41px",
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down("md")]: {
          justifyContent: "center",
          alignItems: "center",
        },
        "& h6": {
          fontFamily: "'Montserrat', sans-serif",
          fontSize: "36px",
          fontWeight: "700",
          lineHeight: "41px",
          color: "#22222",
          marginBottom: "20px",
          [theme.breakpoints.down("md")]: {
            fontSize: "30px",
            lineHeight: "35px",
            textAlign: "center",
          },
        },
        "& p": {
          fontFamily: "'Montserrat', sans-serif",
          fontSize: "13px",
          fontWeight: "400",
          lineHeight: "22px",
          color: colors.doyarAsh,
          marginBottom: "39px",
          [theme.breakpoints.down("md")]: {
            textAlign: "center",
          },
        },
      },
      "& .card": {
        width: "100%",
        height: "343px",
        overflow: "hidden",
        position: "relative",
        borderTopRightRadius: "20px",
        borderBottomRightRadius: "20px",
        [theme.breakpoints.down("md")]: {
          borderTopRightRadius: "0px",
          borderBottomRightRadius: "20px",
          borderTopLeftRadius: "0px",
          borderBottomLeftRadius: "20px",
        },
        "& .card_img": {
          width: "100%",
          height: "100%",
          backgroundColor: "#fff",
          backgroundImage: `url(${img})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",

          "&:hover,:focus": {
            transition: "all .4s ease-in-out",
            transform: "scale(1.1)",
          },
        },
      },
    },
  },
}));
