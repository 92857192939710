import { makeStyles } from "@mui/styles";
import { colors } from "../../../styles/colors";

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: "88px 0",
    "& .wrapper": {
      "& .header": {
        display: "flex",
        flexDirection: "column",
        "& h1": {
          fontFamily: "'Montserrat', sans-serif",
          color: "#181817",
          fontSize: "36px",
          fontWeight: 700,
          lineHeight: "50px",
          textAlign: "center",
          marginBottom: "16px",
          [theme.breakpoints.down("sm")]: {
            fontSize: "24px",
          },
        },
        "& p.intro": {
          fontFamily: "'Montserrat', sans-serif",
          color: "#30415A",
          fontSize: "18px",
          fontWeight: 400,
          lineHeight: "28px",
          textAlign: "center",
          marginBottom: "60px",
          maxWidth: "874px",
          alignSelf: "center",
          [theme.breakpoints.down("md")]: {
            maxWidth: "100%",
          },
        },
      },
      "& .cards": {
        display: "grid",
        gridTemplateColumns: "repeat(4, 1fr)",
        gap: "40px",
        [theme.breakpoints.down("md")]: {
          gridTemplateColumns: "repeat(2, 1fr)",
          gap: "20px",
        },
        "&.mobile": {
          display: "none",
          [theme.breakpoints.down("sm")]: {
            display: "grid",
            gridTemplateColumns: "repeat(1, 1fr)",
            gap: "40px",
          },
        },
        "&.desktop": {
          [theme.breakpoints.down("sm")]: {
            display: "none",
          },
        },
        "& .card": {
          [theme.breakpoints.down("sm")]: {
            display: "none",
            animation: "$fadeInOut .5s ease-in-out",
            "&.active": {
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            },
          },

          "& .icon": {
            marginBottom: "20px",
          },
          "& h6": {
            fontFamily: "'Inter', sans-serif",
            color: "#181817",
            fontSize: "18px",
            fontWeight: 600,
            lineHeight: "19px",
            marginBottom: "16px",
          },
          "& p.desc": {
            fontFamily: "'Inter', sans-serif",
            color: "#30415A",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "28px",
          },
        },
      },
    },
    "& .dots": {
      display: "none",
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        justifyContent: "center",
        marginTop: "60px",
      },
      "& .dot": {
        width: "6px",
        height: "6px",
        borderRadius: "50%",
        backgroundColor: "#B5BFCE",
        margin: "0 2px",
        cursor: "pointer",
        "&.active": {
          backgroundColor: colors.doyarDark,
          width: "23px",
          borderRadius: "20px",
        },
      },
    },
  },
  "@keyframes fadeInOut": {
    "0%": {
      opacity: 0.3,
    },
    "100%": {
      opacity: 1,
    },
  },
}));
