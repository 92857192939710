import React, { useState, useEffect } from "react";
import { useStyles } from "./style";
import ButtonS from "../../buttons/secondary_scrollable";
import { colors } from "../../../styles/colors";
import slide1 from "../../../assets/community/hero-img1.webp";
import slide2 from "../../../assets/community/hero-img2.webp";
import slide3 from "../../../assets/community/hero-img3.webp";
import {
  CommunityIcon1,
  CommunityIcon2,
  CommunityIcon5,
  CommunityIcon6,
} from "../../../assets/icons";

const Hero = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % 3);
    }, 5000); // Adjust the interval time (in milliseconds) as needed

    return () => clearInterval(interval);
  }, [currentSlide]);
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className="wrapper">
        <div className="left">
          <h1>Welcome to our Community</h1>
          <p>
            This is a space where individuals and businesses come together to
            share experiences, build wealth and get support on the benefits of
            non-interest Banking.
          </p>
          <ButtonS bgColor={colors.doyarDark} fontColor={"#fff"} to="benefits">
            Explore Benefits
          </ButtonS>
          <div className="counters">
            <div className="counter">
              <h6 className="yellow">5,000</h6>
              <p>Targetted Members</p>
            </div>
            <div className="counter">
              <h6>Over 5+</h6>
              <p>Experience and Support Points</p>
            </div>
          </div>
        </div>
        <div className="right">
          <div
            className={
              currentSlide === 0 ? "slide slide1 active" : "slide slide1"
            }
          >
            <div className="card card1">
              <CommunityIcon1 /> <span>Non interest banking for all of us</span>
            </div>
            <img src={slide1} alt="slide1" height={"436px"} />
            <div className="card card2">
              <CommunityIcon2 /> <span>Socially Responsible Finance</span>
            </div>
          </div>

          <div
            className={
              currentSlide === 1 ? "slide slide2 active" : "slide slide2"
            }
          >
            <div className="card">
              <CommunityIcon5 />{" "}
              <span>Opportunity to learn, grow and access information</span>
            </div>
            <img src={slide2} alt="slide1" height={"436px"} />
          </div>

          <div
            className={
              currentSlide === 2 ? "slide slide3 active" : "slide slide3"
            }
          >
            <div className="card">
              <CommunityIcon6 />{" "}
              <span>Influence and career skill benefits</span>
            </div>
            <img src={slide3} alt="slide1" height={"436px"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
