import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

import Homepage from "./pages/homepage";
import About from "./pages/about";
import Careers from "./pages/careers";
import Contact from "./pages/contact";
import PrivacyPolicy from "./pages/privacy";
import TermsAndConditions from "./pages/terms";
import Community from "./pages/community";
import Savings from "./pages/savings";
import Send from "./pages/send";
import Security from "./pages/security";
import Faqs from "./pages/faqs";
import Waitlist from "./pages/waitlist";

function App() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/about" element={<About />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/community" element={<Community />} />
        <Route path="/savings" element={<Savings />} />
        <Route path="/send-and-receive-money" element={<Send />} />
        <Route path="/security" element={<Security />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/faqs" element={<Faqs />} />
        <Route path="/waitlist" element={<Waitlist />} />

        <Route
          path="*"
          element={
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h1>404</h1>
            </div>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
