import React, { useState, useEffect } from "react";
import { useStyles } from "./style";
import team from "../../../assets/about/team-banner.jpg";
import ButtonS from "../../buttons/secondary";
import { colors } from "../../../styles/colors";

const Team = () => {
  const [currentText, setCurrentText] = useState(0);
  const texts = ["Dream it", "Build it", "Share"];
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentText((prevText) => (prevText + 1) % texts.length);
    }, 1000); // Adjust the interval time (in milliseconds) as needed

    return () => clearInterval(interval);
  }, [texts]);
  const classes = useStyles();
  return (
    <div className={classes.container + " wrapper"}>
      <div className="here">
        <h4>Here, we </h4>
        <h4 className="texts">
          {texts.map((text, index) => (
            <span key={index} className={index === currentText ? "active" : ""}>
              {text}
            </span>
          ))}
        </h4>
      </div>

      <h6 data-aos="zoom-in" data-aos-easing="linear" data-aos-duration="1500">
        Meet
      </h6>
      <h5 data-aos="zoom-in" data-aos-easing="linear" data-aos-duration="1500">
        The Team
      </h5>
      <img
        src={team}
        alt="team"
        height={"398px"}
        data-aos="zoom-in"
        data-aos-easing="linear"
        data-aos-duration="1500"
      />
      <p data-aos="zoom-in" data-aos-easing="linear" data-aos-duration="1500">
        The team is made up of ex bankers, engineers, developers, academicians
        and designers. We are a smart, tenacious and curious people. We want to
        help you manage, create wealth and do business differently.{" "}
      </p>
      <ButtonS
        bgColor={colors.doyarPeach}
        fontColor="#fff"
        borderColor="#fff"
        to="/careers"
      >
        Explore Careers
      </ButtonS>
    </div>
  );
};

export default Team;
