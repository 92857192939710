import { makeStyles } from "@mui/styles";
import { colors } from "../../../styles/colors";
import bg from "../../../assets/faqs/faqs-bg.jpg";

export const useStyles = makeStyles((theme) => ({
  container: {
    "& .wrapper": {
      paddingTop: "150px",
      fontFamily: "'Montserrat', sans-serif",
      paddingBottom: "30px",
      "& .hero": {
        background: `url(${bg}) no-repeat center`,
        backgroundSize: "cover",
        padding: "31px 25px",
        borderRadius: "20px",
        marginBottom: "20px",
        "& p": {
          color: "#181817",
          fontSize: "18px",
          
        },
       
      },
      [theme.breakpoints.down("md")]: {
        paddingTop: "30px",
      },

      "& h5": {
        fontWeight: "500",
        fontSize: "30px",
        marginBottom: "20px",
        color: colors.doyarDark,
        [theme.breakpoints.down("md")]: {
          fontSize: "20px",
        },
      },
      "& .searchContainer": {
        display: "flex",
        alignItems: "center",
        gap: "16px",
        padding: "12px 20px",
        border: "1px solid " + colors.doyarAsh,
        borderRadius: "13px",
        marginBottom: "20px",
        "& input": {
          width: "100%",
          fontWeight: "400",
          fontSize: "25px",
          color: colors.doyarAsh,
          border: "none",
          outline: "none",
          [theme.breakpoints.down("sm")]: {
            fontSize: "18px",
          },
        },
        [theme.breakpoints.down("sm")]: {
          padding: "12px",
          gap: "12px",
          "& svg ,path": {
            height: "24px",
          },
        },
      },
      "& p.history": {
        fontSize: "18px",
        color: colors.doyarDark,
        fontWeight: "500",
        marginBottom: "30px",
        "& span": {
          textDecoration: "underline",
          colors: colors.doyarAsh + " !important",
          fontWeight: "400 !important",
        },
      },

      "& .columns": {
        display: "grid",
        gridTemplateColumns: "1fr 2fr",
        marginBottom: "40px",
        "& h6": {
          fontWeight: "700",
          fontSize: "32px",
          [theme.breakpoints.down("md")]: {
            fontSize: "20px",
          },
        },
        [theme.breakpoints.down("sm")]: {
          gridTemplateColumns: "1fr",
          gap: "10px",
        },
        "& .faqs": {
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          width: "100%",
          "& .faq": {
            padding: "30px 26px",
            borderRadius: "20px",
            border: "1px solid " + colors.doyarAsh,
            "& .question": {
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              cursor: "pointer",
              "& h3": {
                fontSize: "18px",
                lineHeight: "22px",
                fontWeight: 600,
                color: "#181817",
                [theme.breakpoints.down("sm")]: {
                  fontSize: "16px",
                  lineHeight: "20px",
                },
              },
            },
            "& .answer": {
              fontSize: "16px",
              lineHeight: "34px",
              fontWeight: 400,
              color: "#181817",

              transition: "all 0.3s ease",
              transform: "scaleY(0)",
              "& p": {
                display: "none",
              },
              "& a":{
                color:colors.doyarPeach,
              },

              [theme.breakpoints.down("sm")]: {
                fontSize: "14px",
                lineHeight: "28px",
              },
            },
            "&.opened": {
              background: "#F4F6F9",
              border: "none",
              "& .question": {
                "& h3": {
                  color: colors.doyarPeach,
                },
                "& .icon": {
                  transform: "rotate(180deg)",
                },
              },
              "& .answer": {
                marginTop: "30px",
                transform: "scaleY(1)",
                "& p": {
                  display: "block",
                },
              },
            },
          },
          "& .faq:last-child": {
            marginBottom: "36px",
          },
        },
      },
    },
    "& p.info": {
      fontSize: "18px",
      lineHeight: "22px",
      fontWeight: 400,
      color: colors.doyarAsh,
      textAlign: "center",
      marginTop: "24px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
        lineHeight: "18px",
      },
      "& a": {
        fontWeight: 600,
        color: colors.doyarPeach,
        textDecoration: "none",
        "&:hover": {
          textDecoration: "underline",
        },
      },
    },
  },
}));
