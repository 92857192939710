import { makeStyles } from "@mui/styles";
import { colors } from "../../../styles/colors";

export const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: "97px",

    "& .wrapper": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      marginTop: "100px",
      [theme.breakpoints.down("md")]: {
        marginTop: "30px",
        height: "auto",
      },

      "& h6": {
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: "600",
        fontSize: "36px",
        lineHeight: "64px",
        color: "#222222",
        textAlign: "center",
        marginTop: "30px",
        [theme.breakpoints.down("md")]: {
          fontSize: "24px",
          lineHeight: "32px",
          marginTop: "0px",
        },

        "& span": {
          fontWeight: "700",
          color: colors.doyarPeach,
        },
      },
      "& p": {
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: "400",
        fontSize: "18px",
        lineHeight: "34px",
        color: "#30415A",
        marginTop: "16px",
        textAlign: "center",
        maxWidth: "976px",
        [theme.breakpoints.down("md")]: {
          fontSize: "16px",
          lineHeight: "24px",
          marginTop: "0px",
        },
      },
      "& .banner-cont": {
        height: "100%",
        position: "relative",
      },
      "& img.banner": {
        height: "417px",
        marginTop: "60px",
        width: "100%",
        [theme.breakpoints.down("md")]: {
          height: "auto",
        },
      },
      "& img.logo": {
        top: "40%",
        right: "0%",
        height: "70px",
        position: "absolute",
        opacity: 0,
        animation: "$fadeAndSlide 5s ease-in-out forwards",
        [theme.breakpoints.down("md")]: {
          display: "none",
        },
      },
    },
  },
  "@keyframes fadeAndSlide ": {
    " 0% ": {
      transform: " translateY(-300%)",
      opacity: 0,
    },
    "50%": {
      transform: "translateY(-150%)",
      opacity: 1,
    },

    " 100%": {
      transform: "translateY(0)",
      opacity: 0,
    },
  },
}));
