import React, { useEffect } from "react";
import Navbar from "../../components/navbar";
import Hero from "../../components/send/hero";
import Footer from "../../components/footer";
import JoinBetter from "../../components/general/join-better";
import Cards from "../../components/send/cards";
import Features from "../../components/send/features";

const Send = () => {
  //add meta title and description
  useEffect(() => {
    document.title = "Send/Recieve Money | My Doyar";
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "Hello, we' re Doyar. The benefits of non-interest banking has been limited to a few. So we decided to share it with you. All on your phone."
      );
    document.body.classList.remove("disable-scroll");
  }, []);
  return (
    <>
      <Navbar />
      <Hero />
      <Cards />
      <Features />
      <JoinBetter />
      <Footer />
    </>
  );
};

export default Send;
