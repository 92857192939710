import { makeStyles } from "@mui/styles";
import { colors } from "../../../styles/colors";
import bg from "../../../assets/community/perks-bg.svg";

export const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: "125px",

    fontFamily: "'Montserrat', sans-serif",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "50px",
    },

    "& .wrapper": {
      background: `url(${bg}) no-repeat center`,
      backgroundSize: "cover",
      borderRadius: "20px",
      padding: "50px",
      [theme.breakpoints.down("sm")]: {
        padding: "16px",
      },
      "& h1": {
        fontSize: "36px",
        lineHeight: "45px",
        fontWeight: 700,
        color: "#1D2027",
        textAlign: "center",
        marginBottom: "12px",
        [theme.breakpoints.down("sm")]: {
          fontSize: "24px",
          lineHeight: "30px",
        },
      },
      "& p.intro": {
        fontSize: "18px",
        lineHeight: "22px",
        fontWeight: 400,
        color: "#1D2027",
        textAlign: "center",
        marginBottom: "30px",
        [theme.breakpoints.down("sm")]: {
          fontSize: "16px",
          lineHeight: "20px",
        },
      },
      "& .cards": {
        paddingTop: "51px",
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        gap: "50px",
        [theme.breakpoints.down("sm")]: {
          gridTemplateColumns: "1fr",
          paddingTop: "16px",
        },
        "& .card": {
          padding: "20px 16px",
          borderRadius: "20px",
          background: colors.white,
          [theme.breakpoints.down("sm")]: {
            display: "none",
            animation: "$fadeInOut .5s ease-in-out",
            "&.active": {
              display: "block",
            },
          },

          "& .header": {
            display: "flex",
            alignItems: "center",
            gap: "13px",
            marginBottom: "13px",
            "& h6": {
              fontSize: "18px",
              lineHeight: "34px",
              fontWeight: 600,
              color: colors.doyarDark,
              [theme.breakpoints.down("sm")]: {
                fontSize: "16px",
                lineHeight: "30px",
              },
            },
          },
          "& p": {
            fontSize: "16px",
            lineHeight: "28px",
            fontWeight: 400,
            color: "#30415A",
          },
          "&:hover": {
            transition: "all .1s ease-in-out",
            border: "1px solid " + colors.doyarPeach,
            "& .header": {
              "& h6": {
                transition: "all .1s ease-in-out",

                color: colors.doyarPeach,
              },
              "& svg": {
                transition: "all 1s ease-in-out",
                transform: "rotate(-45deg)",
              },
            },
          },
        },
      },
      "& .dots": {
        display: "none",
        [theme.breakpoints.down("sm")]: {
          display: "flex",
          justifyContent: "center",
          marginTop: "20px",
        },
        "& .dot": {
          width: "6px",
          height: "6px",
          borderRadius: "50%",
          backgroundColor: "#B5BFCE",
          margin: "0 2px",
          cursor: "pointer",
          "&.active": {
            backgroundColor: colors.doyarDark,
            width: "23px",
            borderRadius: "20px",
          },
        },
      },
    },
  },
  "@keyframes fadeInOut": {
    "0%": {
      opacity: 0.3,
    },
    "100%": {
      opacity: 1,
    },
  },
}));
