import { makeStyles } from "@mui/styles";
import { colors } from "../../../styles/colors";
import bg from "../../../assets/community/amb-bg.svg";
import amb1 from "../../../assets/community/amb-1.jpg";
import amb2 from "../../../assets/community/amb-2.jpg";

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: "62px 0px",
    fontFamily: "'Montserrat', sans-serif",
    background: `url(${bg}) no-repeat center`,
    backgroundSize: "cover",
    [theme.breakpoints.down("md")]: {
      padding: "32px 0px",
    },

    "& .tabs": {
      display: "flex",
      justifyContent: "center",
      gap: "16px",
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
      "& button": {
        outline: "none",
        border: "none",
        padding: "2px 14px",
        color: "white",
        fontWeight: 600,
        fontSize: "18px",
        lineHeight: "22px",
        textAlign: "center",
        borderBottom: "4px solid white",
        background: "transparent",
        cursor: "pointer",
        "&.active": {
          transition: "all 0.3s ease",
          color: colors.doyarPeach,
          borderBottomColor: colors.doyarPeach,
        },
      },
    },
    "& .tab-content": {
      paddingTop: "98px",
      display: "none",
      gap: "8px",
      transition: "all 0.3s ease",

      "&.active": {
        display: "grid !important",
        gridTemplateColumns: "1fr 1.5fr",
        [theme.breakpoints.down("md")]: {
          gridTemplateColumns: "1fr",
        },
      },

      [theme.breakpoints.down("md")]: {
        display: "grid",
        gridTemplateColumns: "1fr",
      },
      "& .left": {
        position: "relative",
        "& .left__img_container": {
          overflow: "hidden",
          position: "absolute",
          height: "463.5px",
          width: "431.1px",
          top: "-38px",
          left: "0",
          borderRadius: "20px",
          [theme.breakpoints.down("md")]: {
            width: "90%",
            right: "5%",
          },

          "& .left__img": {
            width: "100%",
            height: "100%",
            backgroundColor: "#fff",

            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            "&:hover,:focus": {
              transition: "all .4s ease-in-out",
              transform: "scale(1.1)",
            },
            "&.student": {
              backgroundImage: `url(${amb1})`,
            },
            "&.regional": {
              backgroundImage: `url(${amb2})`,
            },
          },
        },
        "& .left__shadow": {
          background: "#23395D",
          borderRadius: "20px",
          height: "463.5px",
          width: "431.1px",
          marginLeft: "38px",
          // marginTop: "-32px",
          [theme.breakpoints.down("md")]: {
            width: "90%",
            marginLeft: "5%",
          },
        },
      },
      "& .right": {
        "& h6": {
          fontSize: "36px",
          lineHeight: "64px",
          color: "#fff",
          fontWeight: 700,
          marginBottom: "32px",
          [theme.breakpoints.down("sm")]: {
            fontSize: "16px",
            lineHeight: "24px",
          },
        },
        "& .item": {
          display: "grid",
          gridTemplateColumns: "0.1fr 9fr",
          alignItems: "center",
          gap: "16px",
          marginBottom: "22px",
          "&:last-child": {
            marginBottom: "40px",
          },
          "& span": {
            fontSize: "18px",
            lineHeight: "32px",
            color: "#fff",
            fontWeight: 500,
            [theme.breakpoints.down("sm")]: {
              fontSize: "16px",
              lineHeight: "24px",
            },
          },
          "&.active": {
            animation: "all 2s ease-in-out",
            "& span": {
              color: colors.doyarPeach + " !important",
            },
            "& svg path": {
              fill: colors.doyarPeach,
            },
          },
        },
      },
    },
  },
}));
