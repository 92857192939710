import React, { useState } from "react";
import { useStyles } from "./style";
import { BriefCaseIcon, IconClose, IconOpen } from "../../../assets/icons";
import logo from "../../../assets/images/logo.webp";

const Positions = () => {
  const classes = useStyles();
  const positionIds = []; //position1
  const [open, setOpen] = useState(false);
  const [position, setPosition] = useState("");
  const handleOpen = (id) => {
    setOpen(true);
    setPosition(id);
  };
  const handleClose = () => {
    setOpen(false);
    setPosition("");
  };
  return (
    <div className={classes.container} id="open-positions">
      <div className="wrapper">
        <div className="header">
          <h6>Doyar</h6>
          <h1>Open Positions</h1>
        </div>
        {positionIds.length===0?
        (<div><h3>No new vacancies at the moment</h3></div>):(
        <div className="departments">
          <div className="department">
            <h6 className="dept-title">Customer Experience</h6>
            <div className="positions">
              <div
                className={`position ${
                  open && position === positionIds[0] ? "open" : ""
                }`}
              >
                <div className="position-header">
                  <div className="position-title">
                    <BriefCaseIcon />
                    <h6>Team Lead Customer Experience</h6>
                  </div>
                  <div className="position-location">
                    <div>
                      <span>Location</span>
                      <h6>Hybrid (Remote|Abuja, Nigeria)</h6>
                    </div>
                  </div>
                  {open && position === positionIds[0] ? (
                    <IconClose className="icon" onClick={() => handleClose()} />
                  ) : (
                    <IconOpen
                      className="icon"
                      onClick={() => handleOpen(positionIds[0])}
                    />
                  )}
                </div>
                <div className="position-body">
                  <div className="position-left">
                    <div>
                      <h5>Team Lead Customer Experience</h5>
                      <img src={logo} alt="logo" height={"37px"} />
                    </div>
                    <div>
                      <span>Location</span>
                      <h6>Hybrid (Remote|Abuja, Nigeria)</h6>
                    </div>
                    <div>
                      <span>Salary</span>
                      <h6>Highly Competitive</h6>
                    </div>
                  </div>
                  <div className="position-right">
                    <p>
                      We are looking for an enthusiastic and dedicated customer
                      experience specialist to enhance our customer experience.
                      You will be tracking all points of customer engagement,
                      addressing customer queries, and identifying ways to
                      improve our customer services.
                    </p>
                    <p>
                      To ensure success as a customer experience specialist, you
                      should exhibit in-depth knowledge of customer engagement
                      channels and experience in a similar industry. A top-notch
                      customer experience specialist will be someone whose
                      expertise results in excellent customer relationship
                      management and a positive brand image.
                    </p>
                    <h6>Responsibilities</h6>
                    <ul>
                      <li>
                        Tracking customer experiences across online and offline
                        channels, devices, and touchpoints.
                      </li>
                      <li>
                        Collaborating with developers, as well as the growth and
                        communications teams, to enhance customer services and
                        brand awareness.
                      </li>
                      <li>
                        Aligning customer experience strategies with growth
                        initiatives. as well as informing customers about new
                        product features and functionalities.
                      </li>
                      <li>
                        Identifying customer needs and taking proactive steps to
                        maintain positive experiences.
                      </li>
                      <li>
                        Responding to customer queries in a timely and effective
                        manner, via phone, email, social media, or chat
                        applications.
                      </li>
                      <li>
                        Analysing customer feedback on product ranges and new
                        releases, as well as preparing reports.
                      </li>
                      <li>
                        Performing product tests, evaluating after-sales and
                        support services, and facilitating improvements.
                      </li>
                      <li>
                        Documenting processes and logging technical issues, as
                        well as customer compliments and complaints.
                      </li>
                      <li>
                        Keeping informed of industry trends and new CRM
                        technologies.
                      </li>
                    </ul>
                    <h6>Requirements</h6>
                    <ul>
                      <li>
                        At least 3 years experience as a customer experience
                        specialist, or a similar customer support role.
                      </li>
                      <li>
                        Extensive experience in gathering and interpreting
                        customer experience information.
                      </li>
                      <li>
                        Solid knowledge of online customer engagement platforms
                        and channels.
                      </li>
                      <li>
                        Proficiency in MS Office as well as CX and CRM software,
                        i.e Freshwork
                      </li>
                      <li>
                        Exceptional interpersonal skills and a client-centered
                        approach
                      </li>
                      <li>
                        Great organizational and time management abilities.
                      </li>
                      <li>
                        Superb communication, collaboration, and problem-solving
                        skills.
                      </li>
                      <li></li>
                    </ul>
                    <h6>Perks and Benefit</h6>
                    <ul>
                      <li>Monthly net salary and Quarterly bonus</li>
                      <li>20 paid vacation days</li>
                      <li>Company bonding retreats</li>
                      <li>
                        Great colleagues and amazing work culture and a chance
                        to bring the benefits of non-interest banking to all
                      </li>
                      <li>Training and skills upgrading opportunities.</li>
                    </ul>
                    <div className="note">
                      <spa>Pay Transparency Notice:</spa> Salary and all other
                      total compensation information (bonus eligibility,
                      benefits, and equity) will be discussed in detail during
                      the hiring process.
                    </div>
                    <a
                      href="https://bit.ly/doyar-team-lead-customer-experience"
                      target="_blank"
                      className="apply"
                      rel="noreferrer"
                    >
                      Apply for this Position
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="department">
            <h6 className="dept-title">Finance</h6>
            <div className="positions">
              <div
                className={`position ${
                  open && position === positionIds[2] ? "open" : ""
                }`}
              >
                <div className="position-header">
                  <div className="position-title">
                    <BriefCaseIcon />
                    <h6>Treasury and Investment Officer</h6>
                  </div>
                  <div className="position-location">
                    <div>
                      <span>Location</span>
                      <h6>Abuja, Nigeria</h6>
                    </div>
                  </div>
                  {open && position === positionIds[2] ? (
                    <IconClose className="icon" onClick={() => handleClose()} />
                  ) : (
                    <IconOpen
                      className="icon"
                      onClick={() => handleOpen(positionIds[2])}
                    />
                  )}
                </div>
                <div className="position-body">
                  <div className="position-left">
                    <div>
                      <h5>Treasury and Investment Officer</h5>
                      <img src={logo} alt="logo" height={"37px"} />
                    </div>
                    <div>
                      <span>Location</span>
                      <h6>Abuja, Nigeria</h6>
                    </div>
                    <div>
                      <span>Salary</span>
                      <h6>Highly Competitive</h6>
                    </div>
                  </div>
                  <div className="position-right">
                    <p>
                      You will join us in building a sustainable and profitable
                      non-interest financial company. If you are passionate,
                      driven, and love breaking new ground, we want to meet you!
                    </p>
                    <h6>Roles</h6>
                    <p>
                      The Treasury & Investment Officer is to manage all
                      Investment and Treasury functions to always ensure
                      efficient and seamless services.
                    </p>
                    <h6>Responsibilities</h6>
                    <ul>
                      <li>
                        Engages institutions in the management of our
                        investments and placements.
                      </li>
                      <li>
                        This includes a valuation of the investment to ensure it
                        is in line with the non-interest banking principle.
                      </li>
                      <li>
                        Responsible for investment, profitability risk and
                        compliance analysis.
                      </li>
                      <li>
                        Engages in setting up an in-house investment and
                        Treasury unit.
                      </li>
                      <li>
                        Reviews of transactions with banks to identify
                        reconciliation issues for resolution.
                      </li>
                      <li>
                        Follow up with our banks and obtain all contract
                        letters.
                      </li>
                      <li>Capture of Treasury transactions.</li>
                      <li>
                        Prepares Treasury reports based on market developments
                        to position the Doyar portfolio profitably.
                      </li>
                      <li>Ensures documentation of all Treasury activities.</li>
                      <li>
                        Engages and responds to requests relating to Treasury
                        from different departments and units of Doyar.
                      </li>
                      <li>
                        Participates in audit confirmation of account balances
                        and securities positions as requested by commercial
                        banks, microfinance banks, and the Audit Department.
                      </li>
                      <li>
                        Ensures compliance with statutory and internal policies.
                      </li>
                      <li>
                        Nurtures and maintains relationships with relevant key
                        stakeholders.
                      </li>
                      <li>Performs other duties assigned from time to time.</li>
                      <li>
                        Complies with the principles of the bank's information
                        security policy.
                      </li>
                      <li>
                        Performs other tasks, such as arranging meetings with
                        banks and other stakeholders on treasury-related issues.
                      </li>
                      <li>
                        Works closely with the COO to ensure effective Treasury
                        services for the bank.
                      </li>
                      <li>
                        Follows up on requests and issues addressed to Treasury
                        and ensures timely feedback is provided or the issues
                        are resolved.
                      </li>
                      <li>
                        Provides a link between Treasury and other stakeholders
                        to build relationships crucial to the success of Doyar.
                      </li>
                      <li>
                        Communicates on behalf of the COO with internal and
                        external stakeholders on various matters as may be
                        directed by the COO.
                      </li>
                    </ul>
                    <h6>Educational Qualifiactions</h6>
                    <ul>
                      <li>
                        A good First Degree in Social / Management Sciences or
                        any related discipline
                      </li>
                      <li>
                        A post-graduate Degree in a management-related field is
                        an added advantage
                      </li>
                      <li>
                        4 years of relevant experience in investing, financial
                        services, technology, management consulting, investment
                        banking, and/or marketing—opportunity provides
                        responsibilities scaled to experience.
                      </li>
                      <li>
                        Outstanding relationship management skills and the
                        ability to earn the respect of Doyar colleagues,
                        investors, and enterprises.
                      </li>
                      <li>
                        Exceptional written, communication, and presentation
                        skills, and comfortable moving seamlessly between
                        boardroom and field.
                      </li>
                      <li>
                        Multi-functional skill set across non-interest finance,
                        operations, fundraising, and strategy. Strong research
                        and analysis skills, including financial and strategy
                        analytics.
                      </li>
                      <li>
                        Domain expertise in non-interest investments, financial
                        technology, payment systems, and/or innovative delivery
                        models
                      </li>
                    </ul>
                    <h6>Professional Qualifications</h6>
                    <ul>
                      <li>
                        Membership in ACI, ACIB, ICAN, ACCA, CFA, CPA, or any
                        other related certifications is an added advantage.
                      </li>
                    </ul>
                    <h6>Perks and Benefit</h6>
                    <ul>
                      <li>Monthly net salary and Quarterly bonus</li>
                      <li>20 paid vacation days</li>
                      <li>Company bonding retreats</li>
                      <li>
                        Great colleagues and amazing work culture and a chance
                        to bring the benefits of non-interest banking to all
                      </li>
                      <li>Training and skills upgrading opportunities.</li>
                    </ul>
                    <div className="note">
                      <spa>Pay Transparency Notice:</spa> Salary and all other
                      total compensation information (bonus eligibility,
                      benefits, and equity) will be discussed in detail during
                      the hiring process.
                    </div>
                    <a
                      href="https://bit.ly/doyar-treasury-investment-officer"
                      target="_blank"
                      className="apply"
                      rel="noreferrer"
                    >
                      Apply for this Position
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="department">
            <h6 className="dept-title">Creatives</h6>
            <div className="positions">
              <div
                className={`position ${
                  open && position === positionIds[3] ? "open" : ""
                }`}
              >
                <div className="position-header">
                  <div className="position-title">
                    <BriefCaseIcon />
                    <h6>Content and Community Specialist</h6>
                  </div>
                  <div className="position-location">
                    <div>
                      <span>Location</span>
                      <h6>Hybrid (Remote|Abuja, Nigeria)</h6>
                    </div>
                  </div>
                  {open && position === positionIds[3] ? (
                    <IconClose className="icon" onClick={() => handleClose()} />
                  ) : (
                    <IconOpen
                      className="icon"
                      onClick={() => handleOpen(positionIds[3])}
                    />
                  )}
                </div>
                <div className="position-body">
                  <div className="position-left">
                    <div>
                      <h5>Content and Community Specialist</h5>
                      <img src={logo} alt="logo" height={"37px"} />
                    </div>
                    <div>
                      <span>Location</span>
                      <h6>Hybrid (Remote|Abuja, Nigeria)</h6>
                    </div>
                    <div>
                      <span>Salary</span>
                      <h6>Highly Competitive</h6>
                    </div>
                  </div>
                  <div className="position-right">
                    <p>
                      We're LOOKING FOR a SMART, CURIOUS, AND TENACIOUS content
                      and community specialist. You will join us in building a
                      Financially responsible company that upholds global best
                      practices.
                    </p>
                    <p>
                      You will join us in telling impactful stories about our
                      work, customers, and brand. We want to meet you if you are
                      passionate, driven, and love breaking new ground!
                    </p>
                    <h6>Roles</h6>
                    <p>
                      The content & community specialist will work with the team
                      to tell the stories that matter to us as we build a brand
                      that will transform service delivery in Nigeria. You will
                      be telling stories about our work, how we do it, our
                      customers, and what benefits them. Your stories will
                      increase Doyar brand awareness, convince leads to become
                      customers, educate customers, and drive people to action
                      every time.
                    </p>
                    <p>
                      If you are the kind of person who feels a personal stake
                      in everything that you work on, has a strong sense of
                      ownership, is an executor, has a love for data, and enjoys
                      solving complex problems. You also have a passion for
                      breaking new ground. You know how to foster strong
                      relationships build trust, and work for the success of the
                      entire team we look forward to meeting you!
                    </p>
                    <h6>Responsibilities</h6>
                    <ul>
                      <li>
                        <b>Own and manage all Doyar social media accounts</b>
                        <ol>
                          <li>
                            Grow and implement tactics that grow and expand our
                            followership and drive engagement across our
                            platforms.
                          </li>
                          <li>
                            Create and drive end-to-end social media campaigns.
                          </li>
                          <li>
                            Identify and engage stakeholders in various
                            communities, implementing community engagement that
                            drives brand awareness and customer acquisitions.
                          </li>
                        </ol>
                      </li>
                      <li>
                        <b>Content Creation, Planning and Execution</b>
                        <ol>
                          <li>
                            Create and publish relevant content across different
                            Doyar platforms; web, social, email and physical
                            events.
                          </li>
                          <li>
                            Create content calendars and plans. You will work
                            with the teams to produce content across various
                            formats: video, photography, Audio etc.
                          </li>
                          <li>
                            You will continue to push the boundaries of
                            experimentation and what is possible with content.
                            Come with your best ideas and we will create a place
                            where executing it and aligning them with business
                            goals is possible.
                          </li>
                        </ol>
                      </li>
                      <li>
                        <b>Community Building</b>
                        <ol>
                          <li>
                            Start and manage the Doyar Campus Ambassador
                            Initiatives.
                          </li>
                          <li>Build the Doyar business community.</li>
                          <li>
                            Manage and build the Doyar special communities
                            around book clubs, small businesses, and special
                            interest themes.
                          </li>
                        </ol>
                      </li>
                      <li>
                        <b>Data Reporting and Analysis.</b>
                        <ol>
                          <li>
                            You will pay close attention to the metrics that
                            matter to you, and have them at your fingertips
                            every time.
                          </li>
                          <li>
                            Carry out routine performance and trend analysis,
                            pulling actionable insights from your numbers.
                          </li>
                        </ol>
                      </li>
                    </ul>
                    <h6>Requirements</h6>
                    <ul>
                      <li>
                        At least 3 years of experience managing brand pages &
                        communities with specific experience in brand and
                        marketing writing.
                      </li>
                      <li>
                        BSc in relevant fields: Marketing, Communications.
                      </li>
                      <li>
                        You're an excellent writer: if you don’t feel excellent
                        (enough) don't let it stop you from applying.
                      </li>
                      <li>
                        Experience & high proficiency using Google Analytics,
                        Microsoft Teams, Canva and Buffer.
                      </li>
                      <li>
                        Experimental Mindset; Able to think critically to
                        innovate and solve problems.
                      </li>
                      <li>
                        Proven skills in analysing data, trend analysis, and
                        reporting data insight.
                      </li>
                      <li>
                        Have high fluency in all major social media platforms.
                      </li>
                      <li>
                        Love to travel, organise and manage physical events.
                      </li>
                      <li>
                        Strong storytelling abilities. You understand that good
                        content is engaging because it pulls the consumer in.
                      </li>
                      <li>
                        You understand that good content drives action (sharing,
                        sign-up, comments etc.).
                      </li>
                    </ul>
                    <h6>Perks and Benefit</h6>
                    <ul>
                      <li>Monthly net salary and Quarterly bonus-</li>
                      <li>20 paid vacation days</li>
                      <li>Company bonding retreats</li>
                      <li>
                        Great colleagues and amazing work culture and a chance
                        to bring the benefits of non-interest banking to all
                      </li>
                      <li>Training and skills upgrading opportunities.</li>
                    </ul>
                    <div className="note">
                      <spa>Pay Transparency Notice:</spa> Salary and all other
                      total compensation information (bonus eligibility,
                      benefits, and equity) will be discussed in detail during
                      the hiring process.
                    </div>
                    <a
                      href="http://bit.ly/doyar-content-community-specialist"
                      target="_blank"
                      className="apply"
                      rel="noreferrer"
                    >
                      Apply for this Position
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>)}
      </div>
    </div>
  );
};

export default Positions;
