import { makeStyles } from "@mui/styles";
import { colors } from "../../styles/colors";

export const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "#F4F6F9",
    "& p.info": {
      fontSize: "16px",
      lineHeight: "28px",
      fontWeight: "400",
      color: "#30415A",
      marginBottom: "22px",
      maxWidth: "1100px",
    },
  },
  content: {
    paddingTop: "40px",
    paddingBottom: "45px",
    display: "grid",
    gridTemplateColumns: "repeat(5, 1fr)",
    gridGap: "20px",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },

    "& h6": {
      fontSize: "18px",
      fontWeight: "600",
      color: colors.doyarDark,
      marginBottom: "20px",
    },
    "& ul": {
      display: "flex",
      flexDirection: "column",
      listStyle: "none",
      padding: "0",
      margin: "0",
      gap: "18px",

      "& li a": {
        fontSize: "16px",
        fontWeight: "400",
        color: "rgba(48,65,90,0.6)  ",
        textDecoration: "none",
        transition: "all 0.3s ease-in-out",
        "&:hover": {
          color: colors.doyarPeach,
        },
      },
    },
  },

  copyright: {
    padding: "18px 0",
    backgroundColor: "#1B2D4A",
    color: "#fff",

    "& .wrapper": {
      display: "flex",
      justifyContent: "space-between",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
        alignItems: "center",
      },
    },
    "& span": {
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "20px",

      "&.company": {
        fontWeight: "400 !important",
        [theme.breakpoints.down("sm")]: {
          display: "none",
        },
      },
    },
    "& .socials": {
      display: "flex",
      alignItems: "center",
      gap: "16px",
      [theme.breakpoints.down("sm")]: {
        [theme.breakpoints.down("sm")]: {
          display: "none",
        },
      },
      "& a:hover": {
        "& svg": {
          "& path": {
            fill: colors.doyarPeach,
          },
        },
      },
    },
  },
}));
