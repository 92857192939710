import { makeStyles } from "@mui/styles";
import { colors } from "../../../styles/colors";
import bg from "../../../assets/careers/card-img.webp";

export const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: "83px",
    paddingBottom: "24px",
    "& .wrapper": {
      fontFamily: "'Montserrat', sans-serif",
      display: "grid",
      gridTemplateColumns: "1.2fr 0.8fr",
      gap: "64px",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        gridTemplateColumns: "1fr",
      },
      "& h1": {
        fontWeight: 700,
        fontSize: "44px",
        lineHeight: "55px",
        color: "#1D2027",
        marginBottom: "40px",
        [theme.breakpoints.down("sm")]: {
          textAlign: "center",
          fontSize: "30px",
        },
        "& span": {
          color: colors.doyarPeach,
        },
      },
      "& p": {
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "28px",
        color: "#30415A",
        [theme.breakpoints.down("sm")]: {
          textAlign: "center",
        },
      },
      "& .right": {
        position: "relative",
        width: "100%",
        "&.mobile": {
          display: "none",
          [theme.breakpoints.down("md")]: {
            display: "block",
          },
        },
        "&.desktop": {
          display: "block",
          [theme.breakpoints.down("md")]: {
            display: "none",
          },
        },
        "& .right__img_container": {
          overflow: "hidden",
          position: "absolute",
          height: "367px",
          width: "439px",
          top: "-25px",
          left: "25px",
          borderRadius: "20px",
          [theme.breakpoints.down("md")]: {
            width: "90%",
            left: "5%",
          },

          "& .right__img": {
            width: "100%",
            height: "100%",
            backgroundColor: "#fff",
            backgroundImage: `url(${bg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            "&:hover,:focus": {
              transition: "all .4s ease-in-out",
              transform: "scale(1.1)",
            },
          },
        },
        "& .right__shadow": {
          background: "#F4F6F9",
          borderRadius: "20px",
          height: "367px",
          width: "439px",
          marginRight: "25px",
          // marginTop: "-32px",
          [theme.breakpoints.down("md")]: {
            width: "90%",
            marginRight: "5%",
          },
        },
      },
    },
  },
}));
