import { makeStyles } from "@mui/styles";
import { colors } from "../../styles/colors";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "block",
    padding: "14px 30px",
    textDecoration: "none",
    textAlign: "center",
    fontFamily: "'Inter', sans-serif",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "17px",
    width: "fit-content",
    borderRadius: "8px",
    "&:hover": {
      cursor: "pointer",
    },
    [theme.breakpoints.down("md")]: {
      margin: "0 auto",
    },

    [theme.breakpoints.down("sm")]: {
      padding: "14px 20px",
      width: "100%",
    },
    "&.primary": {
      background: colors.doyarDark,
      color: "#FFFFFF",
    },
    "&.secondary": {
      background: "#FFFFFF",
      color: colors.doyarDark,
      border: "1px solid " + colors.doyarDark,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
    },
  },
}));
