import { makeStyles } from "@mui/styles";
import { colors } from "../../../styles/colors";
import bg from "../../../assets/savings/zigzag-bg.svg";

export const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: "159px",
    // background: `#fff`,
    [theme.breakpoints.down("md")]: {
      paddingBottom: "46px",
      background: `url(${bg}) no-repeat`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      marginBottom: "59px",
    },

    "& .wrapper": {
      background: `url(${bg}) no-repeat`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      fontFamily: "'Montserrat', sans-serif",
      display: "grid",
      gridTemplateColumns: "1fr 1.5fr",
      borderRadius: "32px",
      paddingTop: "46px",
      paddingLeft: "53px",
      gap: "16px",
      [theme.breakpoints.down("md")]: {
        background: `none`,
        gridTemplateColumns: "1fr",
        paddingLeft: "0",
        "& img": {
          width: "100%",
          height: "auto",
        },
      },
      "& .left": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        [theme.breakpoints.down("md")]: {
          alignItems: "center",
        },
      },
      "& h1": {
        fontWeight: 700,
        fontSize: "36px",
        lineHeight: "50px",
        color: "#fff",
        marginBottom: "26px",
        [theme.breakpoints.down("sm")]: {
          textAlign: "center",
          fontSize: "30px",
        },
      },
      "& p": {
        fontWeight: 400,
        fontSize: "18px",
        lineHeight: "34px",
        color: "#fff",
        marginBottom: "30px",

        [theme.breakpoints.down("sm")]: {
          fontSize: "16px",
          lineHeight: "32px",
          textAlign: "center",
        },
      },
      "& a": {
        textDecoration: "none",
        display: "flex",
        fontSize: "16px",
        lineHeight: "20px",
        fontWeight: 500,
        color: colors.doyarPeach,
        gap: "8px",
        marginTop: "32px",
      },
    },
  },
}));
