import { makeStyles } from "@mui/styles";
import { colors } from "../../../styles/colors";
import img from "../../../assets/contact/bottom-banner.webp";

export const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: "121px",
    paddingBottom: "70px",
    "& .wrapper": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    "& .card": {
      width: "100%",
      height: "391px",
      overflow: "hidden",
      position: "relative",
      borderRadius: "20px",
      zIndex: "9",
      [theme.breakpoints.down("md")]: {
        display: "none",
      },

      "& .card_img": {
        width: "100%",
        height: "100%",
        backgroundColor: "#fff",
        backgroundImage: `url(${img})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",

        "&:hover,:focus": {
          transition: "all .4s ease-in-out",
          transform: "scale(1.1)",
        },
      },
    },
    "& .warning": {
      background: "#FAFE36",
      border: "1px solid #1B2D4A",
      padding: "18px 35px",
      borderRadius: "20px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "-70px",
      width: "800px",
      zIndex: "10",
      [theme.breakpoints.down("md")]: {
        maxWidth: "100%",
        marginTop: "0px",
      },
      "& p": {
        color: colors.doyarDark,
        fontSize: "18px",
        fontWeight: 500,
        lineHeight: "28px",
        textAlign: "center",
        marginTop: "25px",
        maxWidth: "600px",
        "& span": {
          color: "#F44336",
        },
        [theme.breakpoints.down("md")]: {
          maxWidth: "100%",
          fontSize: "16px",
          padding: "16px",
        },
      },
    },
  },
}));
