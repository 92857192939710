import React, { useState, useEffect } from "react";
import { useStyles } from "./style";
import { CheckSquare } from "../../../assets/icons";
import ButtonP from "../../buttons/primary";
import { colors } from "../../../styles/colors";

const Ambassadors = () => {
  const [activeTab, setActiveTab] = useState("student");
  const [currentText, setCurrentText] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentText((prevSlide) => (prevSlide + 1) % 5);
    }, 2000); // Adjust the interval time (in milliseconds) as needed

    return () => clearInterval(interval);
  }, [currentText]);
  const classes = useStyles();
  return (
    <div className={classes.container} id="ambassadors">
      <div className="wrapper">
        <div className="tabs">
          <button
            className={activeTab === "student" ? "active" : ""}
            onClick={() => setActiveTab("student")}
          >
            Student Ambassador
          </button>
          <button
            className={activeTab === "regional" ? "active" : ""}
            onClick={() => setActiveTab("regional")}
          >
            Regional Ambassador
          </button>
        </div>
        <div
          className={
            activeTab === "student" ? "tab-content active" : " tab-content"
          }
        >
          <div className="left">
            <div className="left__img_container ">
              <div className="left__img student"></div>
            </div>
            <div className="left__shadow"></div>
          </div>
          <div className="right">
            <h6>Student Ambassador</h6>
            <div className={currentText === 0 ? "item active" : "item"}>
              <CheckSquare />{" "}
              <span>
                Passion for promoting non-interest finance in your region.
              </span>
            </div>
            <div className={currentText === 1 ? "item active" : "item"}>
              <CheckSquare />{" "}
              <span>
                Passion for technology and eager to develop effective
                communication skills.
              </span>
            </div>
            <div className={currentText === 2 ? "item active" : "item"}>
              <CheckSquare />
              <span> Enthusiasm for local and regional teamwork.</span>
            </div>
            <div className={currentText === 3 ? "item active" : "item"}>
              <CheckSquare />{" "}
              <span>
                {" "}
                Excitement for innovation and creative problem-solving.
              </span>
            </div>
            <div className={currentText === 4 ? "item active" : "item"}>
              <CheckSquare />{" "}
              <span>Open to continuous personal development.</span>
            </div>
            <ButtonP
              fontColor={"#fff"}
              bgColor={colors.doyarPeach}
              borderColor={colors.doyarPeach}
              to="https://forms.gle/zBLcw5JDxmn3Zt9r7"
              a="true"
            >
              Student Ambassador Program Waitlist
            </ButtonP>
          </div>
        </div>

        <div
          className={
            activeTab === "regional" ? "tab-content active" : " tab-content"
          }
        >
          <div className="left">
            <div className="left__img_container ">
              <div className="left__img regional"></div>
            </div>
            <div className="left__shadow"></div>
          </div>
          <div className="right">
            <h6>Regional Ambassador</h6>
            <div className={currentText === 0 ? "item active" : "item"}>
              <CheckSquare />{" "}
              <span>
                Passion for promoting non-interest finance in your region.
              </span>
            </div>
            <div className={currentText === 1 ? "item active" : "item"}>
              <CheckSquare />{" "}
              <span>Eager to develop effective communication skills.</span>
            </div>
            <div className={currentText === 2 ? "item active" : "item"}>
              <CheckSquare />
              <span> Enthusiasm for local and regional teamwork.</span>
            </div>
            <div className={currentText === 3 ? "item active" : "item"}>
              <CheckSquare />{" "}
              <span>
                {" "}
                Excitement for innovation and creative problem-solving.
              </span>
            </div>
            <div className={currentText === 4 ? "item active" : "item"}>
              <CheckSquare />{" "}
              <span>Open to continuous personal development.</span>
            </div>
            <ButtonP
              fontColor={"#fff"}
              bgColor={colors.doyarPeach}
              borderColor={"#fff"}
              to=""
            >
              Coming soon!
            </ButtonP>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ambassadors;
