import React from "react";
import { useStyles } from "./style";
import heroImg from "../../../assets/careers/hero-img.webp";
import ButtonS from "../../buttons/secondary_scrollable";
import { colors } from "../../../styles/colors";

const Hero = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className="wrapper">
        <div>
          <h6
            data-aos="zoom-in"
            data-aos-easing="linear"
            data-aos-duration="1000"
          >
            Careers at Doyar
          </h6>
          <h1
            data-aos="fade-right"
            data-aos-easing="linear"
            data-aos-duration="1000"
          >
            We are Building the Best Place to Work
          </h1>
          <p
            data-aos="zoom-in"
            data-aos-easing="linear"
            data-aos-duration="1000"
          >
            We offer the best workplace, promoting an inclusive culture,
            employee well-being, and continuous learning. Employees contribute
            to impactful and meaningful projects in a collaborative and
            innovative environment.{" "}
          </p>
          <ButtonS
            fontColor={"#fff"}
            bgColor={colors.doyarDark}
            dataAos="zoom-in"
            dataAosEasing="linear"
            dataAosDuration="1000"
            to="open-positions"
          >
            See Open Positions
          </ButtonS>
        </div>
        <img
          className="desktop"
          src={heroImg}
          alt="hero-img"
          height={"509px"}
          data-aos="fade-left"
          data-aos-easing="linear"
          data-aos-duration="1000"
        />
        <img
          className="mobile"
          src={heroImg}
          alt="hero-img"
          height={"509px"}
          data-aos="zoom-in"
          data-aos-easing="linear"
          data-aos-duration="1000"
        />
      </div>
    </div>
  );
};

export default Hero;
