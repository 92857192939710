import React from "react";
import { useStyles } from "./style";
import { Link } from "@mui/material";
import { ArrowRight } from "../../../assets/icons";
import img from "../../../assets/savings/savings-card-img2.webp";

const Margin = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className="wrapper">
        <div className="left">
          <h1>Higher Profit Margin with No Interest</h1>
          <p>
            Increase profit margins with our no-interest approach, ensuring more
            earnings for sustainable growth and responsible finance.
          </p>
          {/* <Link to="/">
            <u>Start Saving Today </u>
            <ArrowRight />{" "}
          </Link> */}
        </div>
        <img src={img} height={"420px"} alt="card-img" />
      </div>
    </div>
  );
};

export default Margin;
