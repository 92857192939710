import React from "react";
import { useStyles } from "./style";
import bg from "../../../assets/about/hero-img.webp";
import logo from "../../../assets/images/logo-white.webp";

const Hero = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className="wrapper">
        <h6
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1000"
        >
          Hello! <span>We are Doyar</span>
        </h6>
        <p data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1000">
          We believe in revolutionizing the way people interact with their
          finances. We are committed to providing a unique and ethical banking
          experience that aligns with your values.{" "}
        </p>
        <div className="banner-cont">
          <img
            src={logo}
            alt="Doyar Bank"
            className="logo"
            // data-aos="fade-down"
            // data-aos-easing="linear"
            // data-aos-duration="1500"
          />
          <img src={bg} className="banner" alt="Doyar Bank" />
        </div>
      </div>
    </div>
  );
};

export default Hero;
