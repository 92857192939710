import { makeStyles } from "@mui/styles";
import { colors } from "../../../styles/colors";

export const useStyles = makeStyles((theme) => ({
  container: {
    // paddingBottom: "97px",
    background: `#fff`,

    "& .wrapper": {
      fontFamily: "'Montserrat', sans-serif",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingTop: "130px",
      "& .left": {
        maxWidth: "650px",
      },
      "& .license": {
        marginTop: "2rem",
        display: "flex",
        gap: "16px",
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {
          flexDirection: "column",
        },
      },
      "& img": {
        "&.mobile": {
          display: "none",
          [theme.breakpoints.down("md")]: {
            display: "block",
          },
        },
        "&.desktop": {
          display: "block",
          [theme.breakpoints.down("md")]: {
            display: "none",
          },
        },
      },
      [theme.breakpoints.down("md")]: {
        paddingTop: "80px",
        flexDirection: "column",
        justifyContent: "center",
        gap: "40px",
        "& svg": {
          height: "34px",
        },

        "& img.hero-img": {
          width: "100%",
          height: "auto",
        },
      },

      "& h6": {
        color: colors.doyarPeach,
        fontSize: "18px",
        fontWeight: 600,
        lineHeight: "22px",
        [theme.breakpoints.down("md")]: {
          textAlign: "center",
        },
      },
      "& h1": {
        color: "#181817",
        fontSize: "36px",
        fontWeight: 700,
        lineHeight: "50px",
        marginBottom: "16px",
        [theme.breakpoints.down("md")]: {
          textAlign: "center",
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: "30px",
        },
      },
      "& p": {
        color: "#30415A",
        fontSize: "18px",
        fontWeight: 400,
        lineHeight: "34px",

        [theme.breakpoints.down("md")]: {
          textAlign: "center",
        },
        "&.intro": {
          marginBottom: "54px",
        },
      },
      "& span": {
        display: "inline-block",
        color: colors.doyarAsh,
        fontSize: "22px",
        fontWeight: 400,
        lineHeight: "34px",
        [theme.breakpoints.down("sm")]: {
          fontSize: "12px",
        },
      },
    },
  },
}));
