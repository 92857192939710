import React, { useEffect } from "react";
import Navbar from "../../components/navbar";
import Hero from "../../components/contact/hero";
import Footer from "../../components/footer";
import Form from "../../components/contact/form";
import Danger from "../../components/contact/danger";

const Contact = () => {
  //add meta title and description
  useEffect(() => {
    document.title = "Contact us | My Doyar";
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "Hello, we' re Doyar. The benefits of non-interest banking has been limited to a few. So we decided to share it with you. All on your phone."
      );
    document.body.classList.remove("disable-scroll");
  }, []);
  return (
    <>
      <Navbar />
      <Hero />
      <Form />
      <Danger />
      <Footer />
    </>
  );
};

export default Contact;
