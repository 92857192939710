import { makeStyles } from "@mui/styles";
import { colors } from "../../../styles/colors";

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: "125px 0",
    fontFamily: "'Montserrat', sans-serif",
    "&.wrapper": {
      width: "833px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.down("md")]: {
        width: "90%",
        padding: "50px 0",
      },
      "& h1": {
        fontSize: "36px",
        lineHeight: "50px",
        fontWeight: 600,
        color: "#181817",
        textAlign: "center",
        marginBottom: "26px",
        [theme.breakpoints.down("sm")]: {
          fontSize: "24px",
          lineHeight: "30px",
        },
      },
      "& .faqs": {
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        width: "100%",
        "& .faq": {
          padding: "30px 26px",
          borderRadius: "20px",
          border: "1px solid " + colors.doyarAsh,
          "& .question": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            cursor: "pointer",
            "& h3": {
              fontSize: "18px",
              lineHeight: "22px",
              fontWeight: 600,
              color: "#181817",
              [theme.breakpoints.down("sm")]: {
                fontSize: "16px",
                lineHeight: "20px",
              },
            },
          },
          "& .answer": {
            fontSize: "16px",
            lineHeight: "34px",
            fontWeight: 400,
            color: "#181817",

            transition: "all 0.3s ease",
            transform: "scaleY(0)",
            "& p": {
              display: "none",
            },

            [theme.breakpoints.down("sm")]: {
              fontSize: "14px",
              lineHeight: "28px",
            },
          },
          "&.opened": {
            background: "#F4F6F9",
            border: "none",
            "& .question": {
              "& h3": {
                color: colors.doyarPeach,
              },
              "& .icon": {
                transform: "rotate(180deg)",
              },
            },
            "& .answer": {
              marginTop: "30px",
              transform: "scaleY(1)",
              "& p": {
                display: "block",
              },
            },
          },
        },
        "& .faq:last-child": {
          marginBottom: "36px",
        },
      },
      "& p.info": {
        fontSize: "18px",
        lineHeight: "22px",
        fontWeight: 400,
        color: colors.doyarAsh,
        textAlign: "center",
        [theme.breakpoints.down("sm")]: {
          fontSize: "14px",
          lineHeight: "18px",
        },
        "& a": {
          fontWeight: 600,
          color: colors.doyarPeach,
          textDecoration: "none",
          "&:hover": {
            textDecoration: "underline",
          },
        },
      },
    },
  },
}));
