import { makeStyles } from "@mui/styles";
import bg from "../../../assets/homepage/info-bg.svg";
import { colors } from "../../../styles/colors";

export const useStyles = makeStyles((theme) => ({
  container: {
    zIndex: 1,
    background: "transparent",
    [theme.breakpoints.down("md")]: {
      backgroundColor: colors.doyarDark,
      backgroundImage: `url(${bg})`,
      backgroundRepeat: "repeat-x",
      backgroundSize: "cover",
      backgroundPosition: "center",
    },
    "& .wrapper": {
      borderRadius: "32px",
      marginTop: "97px",
      padding: "83px 94px",
      backgroundColor: colors.doyarDark,
      backgroundImage: `url(${bg})`,
      backgroundRepeat: "repeat-x",
      backgroundSize: "cover",
      backgroundPosition: "center",
      [theme.breakpoints.down("md")]: {
        padding: "68px 0",
        marginTop: "0px",
        borderRadius: "0px",
        background: "transparent",
      },
    },
    "& .grid": {
      display: "grid",
      gridTemplateColumns: "repeat(2, 1fr)",
      gap: "90px",
      [theme.breakpoints.down("sm")]: {
        gridTemplateColumns: "1fr",
        gap: "50px",
        // padding: "0 20px",
      },

      "& .item": {
        display: "flex",
        alignItems: "center",
        gap: "40px",
        [theme.breakpoints.down("md")]: {
          flexDirection: "column",
          justifyContent: "center",
        },
        "& h6": {
          fontFamily: "'Montserrat', sans-serif",
          fontSize: "21px",
          lineHeight: "25px",
          fontWeight: "700",
          color: "#eff1f3",
          marginBottom: "16px",
          [theme.breakpoints.down("md")]: {
            textAlign: "center",
          },
        },
        "& p": {
          fontFamily: "'Montserrat', sans-serif",
          fontSize: "12px",
          lineHeight: "18px",
          fontWeight: "500",
          color: "#cccccc",
          [theme.breakpoints.down("md")]: {
            textAlign: "center",
          },
        },
        "& .icon": {
          background: "#222222",
          minWidth: "160px",
          minHeight: "160px",
          boxShadow: "0.66666px 0.66666px 2.2037px 3.85% #000000",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "32px",
        },
        "& .text": {
          maxWidth: "360px",
          [theme.breakpoints.down("md")]: {
            maxWidth: "100%",
          },
        },
      },
    },
  },
}));
