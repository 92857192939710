import { makeStyles } from "@mui/styles";
import { colors } from "../../../styles/colors";
import bg from "../../../assets/about/zigzag.svg";

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: "69px 0",
    background: `url(${bg}) no-repeat`,
    backgroundSize: "cover",
    backgroundPosition: "center",

    "& .wrapper": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        gap: "50px",
      },
      "& .left": {
        maxWidth: "619px",
        [theme.breakpoints.down("md")]: {
          maxWidth: "100%",
        },
        "& h6": {
          fontFamily: "'Montserrat', sans-serif",
          fontWeight: "600",
          fontSize: "18px",
          lineHeight: "22px",
          color: colors.doyarPeach,
          [theme.breakpoints.down("md")]: {
            textAlign: "center",
          },
        },
        "& h4": {
          fontFamily: "'Montserrat', sans-serif",
          fontWeight: "700",
          fontSize: "36px",
          lineHeight: "50px",
          color: "#181817",
          marginBottom: "16px",
          [theme.breakpoints.down("md")]: {
            textAlign: "center",
          },
        },
        "& p": {
          fontFamily: "'Montserrat', sans-serif",
          fontWeight: "400",
          fontSize: "18px",
          lineHeight: "34px",
          color: colors.doyarDark,
          [theme.breakpoints.down("md")]: {
            textAlign: "center",
          },
        },
      },
      "& img": {
        [theme.breakpoints.down("md")]: {
          height: "auto",
          width: "100%",
        },
      },
    },
  },
}));
