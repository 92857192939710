import React from "react";
import { useStyles } from "./info.style";
import { SafeIcon, ShieldIcon } from "../../../assets/icons";
import { colors } from "../../../styles/colors";

const Info = () => {
  const classes = useStyles();
  return (
    <div className={classes.container} id="info">
      <div className="wrapper">
        <div className="grid">
          <div className="item">
            <div className="icon">
              <ShieldIcon />
            </div>
            <div className="text">
              <h6>Fast and secure cashless payments</h6>
              <p>
                Send and receive money using our secured and fast platform,
                which ensures your transactions are safe at all times.{" "}
                <span style={{ color: colors.doyarPeach }}>Coming soon...</span>
              </p>
            </div>
          </div>
          <div className="item">
            <div className="icon">
              <SafeIcon />
            </div>
            <div className="text">
              <h6>Savings Plan that Suits your lifestyle</h6>
              <p>
                Explore savings plan designed to make saving simple, convenient
                and a high profit yield for everyone.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Info;
