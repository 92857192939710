import React, { useEffect, useState } from "react";
import { useStyles } from "./style";
import {
  CareerIcon1,
  CareerIcon2,
  CareerIcon3,
  CareerIcon4,
} from "../../../assets/icons";

const Benefits = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % 4);
    }, 5000); // Adjust the interval time (in milliseconds) as needed

    return () => clearInterval(interval);
  }, [currentSlide]);
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className="wrapper">
        <div className="header">
          <h1
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="1000"
          >
            Benefits of Joining the Doyar Team
          </h1>
          <p
            className="intro"
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="1000"
          >
            World-class banking experiences, built from scratch, with a
            relentless focus on innovation and customer satisfaction..
          </p>
        </div>
        <div className="cards desktop">
          <div
            className={"card"}
            data-aos="zoom-in-up"
            data-aos-easing="linear"
            data-aos-duration="1000"
          >
            <CareerIcon1 className="icon" />
            <h6>Competitive salaries & bonuses</h6>
            <p className="desc">
              We reward employee talent with competitive salaries and
              performance-based bonuses.
            </p>
          </div>
          <div
            className={"card"}
            data-aos="zoom-in-up"
            data-aos-easing="linear"
            data-aos-duration="1000"
          >
            <CareerIcon2 className="icon" />
            <h6>Career & mindset growth</h6>
            <p className="desc">
              At Doyar, we specialize in unlocking inner potentials and
              fostering the seeds of success, making it our forte.
            </p>
          </div>
          <div
            className={"card"}
            data-aos="zoom-in-up"
            data-aos-easing="linear"
            data-aos-duration="1000"
          >
            <CareerIcon3 className="icon" />
            <h6>Remote & friendly work culture</h6>
            <p className="desc">
              We offer boundless collaboration, wherever you are. Embracing
              Remote & hybrid Friendships.
            </p>
          </div>
          <div
            className={"card"}
            data-aos="zoom-in-up"
            data-aos-easing="linear"
            data-aos-duration="1000"
          >
            <CareerIcon4 className="icon" />
            <h6>Annual leaves & holidays</h6>
            <p className="desc">
              Get annual leave and holiday benefits refreshing escapes,
              cherished Moments. Embracing the gift of time.
            </p>
          </div>
        </div>
        <div className="cards mobile">
          <div className={currentSlide === 0 ? "card active" : "card"}>
            <CareerIcon1 className="icon" />
            <h6>Competitive salaries & bonuses</h6>
            <p className="desc">
              We reward employee talent with competitive salaries and
              performance-based bonuses.
            </p>
          </div>
          <div className={currentSlide === 1 ? "card active" : "card"}>
            <CareerIcon2 className="icon" />
            <h6>Career & mindset growth</h6>
            <p className="desc">
              At Doyar, we specialize in unlocking inner potentials and
              fostering the seeds of success, making it our forte.
            </p>
          </div>
          <div className={currentSlide === 2 ? "card active" : "card"}>
            <CareerIcon3 className="icon" />
            <h6>Remote & friendly work culture</h6>
            <p className="desc">
              We offer boundless collaboration, wherever you are. Embracing
              Remote & hybrid Friendships.
            </p>
          </div>
          <div className={currentSlide === 3 ? "card active" : "card"}>
            <CareerIcon4 className="icon" />
            <h6>Annual leaves & holidays</h6>
            <p className="desc">
              Get annual leave and holiday benefits refreshing escapes,
              cherished Moments. Embracing the gift of time.
            </p>
          </div>
        </div>
        <div className="dots">
          <div
            className={currentSlide === 0 ? "dot active" : "dot"}
            onClick={() => setCurrentSlide(0)}
          ></div>
          <div
            className={currentSlide === 1 ? "dot active" : "dot"}
            onClick={() => setCurrentSlide(1)}
          ></div>
          <div
            className={currentSlide === 2 ? "dot active" : "dot"}
            onClick={() => setCurrentSlide(2)}
          ></div>
          <div
            className={currentSlide === 3 ? "dot active" : "dot"}
            onClick={() => setCurrentSlide(3)}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Benefits;
