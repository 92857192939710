import { makeStyles } from "@mui/styles";
import { colors } from "../../../styles/colors";
import bg from "../../../assets/send/red-bg.svg";

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: "32px",
    background: `url(${bg}) no-repeat`,
    backgroundSize: "cover",
    backgroundPosition: "center",

    "& .wrapper": {
      fontFamily: "'Montserrat', sans-serif",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",

      "& .right": {
        maxWidth: "619px",
      },
      "& img": {
        "&.mobile": {
          display: "none",
          [theme.breakpoints.down("md")]: {
            display: "block",
          },
        },
        "&.desktop": {
          display: "block",
          [theme.breakpoints.down("md")]: {
            display: "none",
          },
        },
      },
      [theme.breakpoints.down("md")]: {
        paddingTop: "80px",
        flexDirection: "column-reverse",
        justifyContent: "center",
        gap: "40px",

        "& img": {
          width: "100%",
          height: "auto",
        },
      },

      "& h1": {
        color: "#fff",
        fontSize: "36px",
        fontWeight: 700,
        lineHeight: "50px",
        marginBottom: "16px",
        [theme.breakpoints.down("md")]: {
          textAlign: "center",
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: "30px",
        },
      },
      "& p": {
        color: "#fff",
        fontSize: "18px",
        fontWeight: 400,
        lineHeight: "34px",
        marginBottom: "54px",
        [theme.breakpoints.down("md")]: {
          textAlign: "center",
        },
      },
    },
  },
}));
