import React from "react";
import { useStyles } from "./style";
import img from "../../../assets/security/card-img.webp";

const Report = () => {
  const classes = useStyles();
  return (
    <div className={classes.container + " wrapper"}>
      <div>
        <h1>Report Security Findings or Malicious Activity</h1>
        <p style={{ marginBottom: "26px" }}>
          Our commitment to security is unwavering, and we encourage you to be
          our extra set of vigilant eyes. If you come across any security issues
          or suspect malicious activity within our systems or community, don't
          hesitate to report it. Your vigilance helps us maintain the highest
          level of security and ensures that our shared space remains a trusted
          and secure environment for all.
        </p>
        <p>
          You can also share your report via email to:{" "}
          <a href="mailto:support@doyarbank.com">support@doyarbank.com </a>
          If you feel there's sensitive data that needs to be encrypted, feel
          free to reach out to our team.
        </p>
      </div>
      <img src={img} height={"444px"} alt="hand" />
    </div>
  );
};

export default Report;
