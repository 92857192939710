import React from "react";
import { useStyles } from "./style";
import heroImg from "../../../assets/careers/hero-img.webp";
import ButtonS from "../../buttons/secondary";
import { colors } from "../../../styles/colors";

const Pulse = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className="wrapper">
        <div>
          <h1
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="1400"
          >
            Our <span>Pulse</span> and <span>Aura</span>
          </h1>
          <p
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="1400"
          >
            Our unwavering commitment ensures that we persistently explore,
            adapt, and collaborate until we discover the optimal resolution for
            our valued customers.
          </p>
        </div>
        <div
          className="right desktop"
          data-aos="fade-left"
          data-aos-easing="linear"
          data-aos-duration="1400"
        >
          <div className="right__img_container ">
            <div className="right__img"></div>
          </div>
          <div className="right__shadow"></div>
        </div>
        <div className="right mobile">
          <div className="right__img_container ">
            <div className="right__img"></div>
          </div>
          <div className="right__shadow"></div>
        </div>
      </div>
    </div>
  );
};

export default Pulse;
