import React from "react";
import { useStyles } from "./style";
import img from "../../../assets/contact/messaging-icon.webp";

const Form = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className="wrapper">
        <div className="text">
          <h1>You can Write to us Directly</h1>
          <p>
            Provide the details of your issue, tell us as much as you can about
            the problem, and we'll be in touch soon.
          </p>
          <img src={img} height={"429px"} alt="icon" />
        </div>
        <form>
          <div className="form-group">
            <label>Full Name</label>
            <input type="text" placeholder="Tell us your fullname" />
          </div>
          <div className="form-row">
            <div className="form-group">
              <label>Email</label>
              <input type="email" placeholder="Type your email" />
            </div>
            <div className="form-group">
              <label>Mobile</label>
              <input type="text" placeholder="Type your mobile number" />
            </div>
          </div>
          <div className="form-group">
            <label>Message</label>
            <textarea placeholder="Type your message" rows={5} />
          </div>
          <button>Send Message</button>
        </form>
        <img src={img} height={"429px"} alt="icon" className="desktop" />
      </div>
    </div>
  );
};

export default Form;
