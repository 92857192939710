import React from "react";
import { useStyles } from "./style";
import heroImg from "../../../assets/security/hero-img.jpg";
import { SecurityIcon1, SecurityIcon2 } from "../../../assets/icons";

const Hero = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className="wrapper">
        <div className="left">
          <h1>
            We Ensure <span>100%</span> Account Security & Privacy
          </h1>
          <p>
            Security of your savings and other transactions is a priority for
            Doyar. We keep your transactions safe and secure with multiple
            layers of protection.
          </p>
        </div>
        <div className="right">
          <div className={"slide"}>
            <div className="card card1">
              <SecurityIcon2 />{" "}
              <span>Your Transactions are Safe & Secured</span>
            </div>
            <img src={heroImg} alt="hero-img" height={"406px"} />
            <div className="card card2">
              <SecurityIcon1 /> <span>Advanced Fraud Detection System</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
