import React from "react";
import { useStyles } from "./style";
import heroImg from "../../../assets/send/hero-img.webp";
import cbn from "../../../assets/send/cbn-logo.svg";
import ndic from "../../../assets/send/ndic-logo.svg";
import ButtonS from "../../buttons/secondary";
import { colors } from "../../../styles/colors";
import {
  History,
  HistoryEdu,
  HistoryRounded,
  HistorySharp,
  LockClock,
  PunchClock,
  Timelapse,
  Timer,
} from "@mui/icons-material";

const Hero = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className="wrapper">
        <div className="left">
          <h1
            data-aos="fade-right"
            data-aos-easing="linear"
            data-aos-duration="1000"
          >
            Seamless Account Funding and Quick Transfers
          </h1>
          <p
            data-aos="zoom-in"
            data-aos-easing="linear"
            data-aos-duration="1000"
            className="intro"
          >
            Manage your finances with our easy account funding and instant money
            transfers.
          </p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
            }}
          >
            <p
              style={{
                fontSize: "16px !important",
                color: colors.doyarPeach,
              }}
            >
              Coming Soon...
            </p>
            <HistorySharp style={{ color: colors.doyarPeach }} />{" "}
          </div>
        </div>
        <img
          className="desktop"
          src={heroImg}
          alt="hero-img"
          height={"514px"}
          data-aos="fade-left"
          data-aos-easing="linear"
          data-aos-duration="1000"
        />
        <img
          className="mobile hero-img"
          src={heroImg}
          alt="hero-img"
          height={"514px"}
          data-aos="zoom-in"
          data-aos-easing="linear"
          data-aos-duration="1000"
        />
      </div>
    </div>
  );
};

export default Hero;
