import React from "react";
import { useStyles } from "./style";
import heroImg from "../../../assets/careers/hero-img.webp";
import ButtonS from "../../buttons/secondary";
import { colors } from "../../../styles/colors";

const Profit = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className="wrapper">
        <div
          className="left desktop"
          data-aos="fade-right"
          data-aos-easing="linear"
          data-aos-duration="1400"
        >
          <div className="left__img_container ">
            <div className="left__img"></div>
          </div>
          <div className="left__shadow"></div>
        </div>
        <div className="left mobile">
          <div className="left__img_container ">
            <div className="left__img"></div>
          </div>
          <div className="left__shadow"></div>
        </div>
        <div>
          <h1
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="1400"
          >
            Our <span>Profit</span> Principle <br />
            using <span>Mudarabah</span>
          </h1>
          <p
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="1400"
          >
            “Mudarabah”, The term refers to a form of business contract in which
            one party brings the funds and the other brings expertise. The
            proportionate share in profit is determined by mutual agreement.
            Doyar brings world class expertise in the management of our users
            funds.
          </p>
          <p
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="1400"
          >
            We focus on responsible finance, where we believe in being clear,
            doing what's right, and sharing profits fairly. Our goal is to
            encourage ethical investments that connect old-fashioned values with
            modern financial solutions, making the financial system fair and
            open to everyone.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Profit;
