import { makeStyles } from "@mui/styles";
import { colors } from "../../styles/colors";

export const useStyles = makeStyles((theme) => ({
  nav: {
    marginTop: "24px",
    marginBottom: "-100px",
    "&.wrapper": {
      [theme.breakpoints.down("md")]: {
        width: "auto",
        maxWidth: "auto",
        margin: "auto",
        padding: "auto",
      },
    },
  },
  container: {
    zIndex: "999",
    background: "rgba(255, 255, 255, 0.85)",
    padding: "15px 25px",
    borderRadius: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "relative",
    height: "74px",
    boxShadow: "0 4px 40px 0 rgba(27, 45, 74, 0.08)",
    "& .close-icon": {
      display: "none",
      marginRight: "5%",
      [theme.breakpoints.down("md")]: {
        display: "block",
        cursor: "pointer",
      },
    },
    "& .menu-icon": {
      display: "none",
      [theme.breakpoints.down("md")]: {
        display: "block",
        cursor: "pointer",
      },
    },
    [theme.breakpoints.down("md")]: {
      borderRadius: "0",
    },

    "& .navbar ": {
      listStyle: "none",
      display: "flex",
      alignItems: "center",
      gap: "20px",
      // position: "relative",
      "& .socials": {
        display: "none",
      },

      [theme.breakpoints.down("md")]: {
        transition: "all 0.3s ease-in-out",
        position: "fixed",
        top: "-100%",
        margin: "0 auto",
        left: "0",
        right: "0",
        width: "100%",
        flexDirection: "column",
        background: "#F8F8F8",
        textAlign: "center",
        padding: "20px 0",
      },

      "& .navbar-item": {
        display: "flex",
        alignItems: "center",
        gap: "3px",
        cursor: "pointer",
        color: "#181817",
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "17px",
        "&:hover": {
          color: colors.doyarPeach,
        },

        "& .navbar-link": {
          textDecoration: "none",
          color: "#181817",
          fontFamily: "'Montserrat', sans-serif",
          fontWeight: "400",
          fontSize: "14px",
          lineHeight: "17px",
          "&:hover": {
            color: colors.doyarPeach,
          },
          "&.active": {
            color: colors.doyarPeach,
            textDecoration: "underline",
          },
        },
        "&.download-btn": {
          marginRight: "0",
          marginLeft: "150px",
          [theme.breakpoints.down("md")]: {
            marginLeft: "0",
          },
        },

        "& .button": {
          textDecoration: "none",
          padding: "12px 30px",
          background: colors.doyarPeach,
          borderRadius: "10px",
          fontFamily: "'Inter', sans-serif",
          fontWeight: "500",
          fontSize: "14px",
          lineHeight: "17px",
          color: "#FFFFFF",
        },
        "& .open": {
          transition: "all 0.3s ease-in-out",
          top: "76px !important",
          [theme.breakpoints.down("md")]: {
            top: "0px !important",
            borderRadius: "0",
            zIndex: "999",
          },
        },
      },

      "& .dropdown": {
        listStyle: "none",
        position: "absolute",
        background: "#FFFFFF",
        padding: "26px",
        borderRadius: "13px",
        display: "flex",
        flexDirection: "column",
        top: "-1000%",
        gap: "20px",
        transition: "all 0.3s ease-in-out",

        [theme.breakpoints.down("md")]: {
          position: "fixed",
          top: "-1000%",
          left: "0",
          right: "0",
          margin: "0 auto",
          width: "100%",
          height: "100%",
        },

        "& .dropdown-item": {
          "& a": {
            display: "flex",
            gap: "13px",
            textDecoration: "none",
            color: "#30415A",
            fontFamily: "'Montserrat', sans-serif",
            [theme.breakpoints.down("md")]: {
              display: "block",
              textAlign: "center",
            },
            "&.active": {
              color: colors.doyarPeach,
            },
            "& .down-text": {
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              "& h6": {
                fontWeight: "500",
                fontSize: "16px",
                display: "flex",
                gap: "24px",
                [theme.breakpoints.down("md")]: {
                  display: "block",
                  gap: "0",
                },
              },
              "& span": {
                fontWeight: "400",
                fontSize: "12px",
              },
            },
            "&:hover": {
              transition: "all 0.3s ease-in-out",
              color: colors.doyarPeach + " !important",
              "& svg": {
                transition: "all 0.3s ease-in-out",
                color: colors.doyarPeach + " !important",
                "& path": {
                  transition: "all 0.3s ease-in-out",
                  fill: colors.doyarPeach + " !important",
                },
                "& circle": {
                  transition: "all 0.3s ease-in-out",
                  fill: colors.doyarPeach + " !important",
                },
              },
              "& .arrow": {
                transition: "all 0.3s ease-in-out",
                display: "block !important",
                [theme.breakpoints.down("md")]: {
                  display: "none !important",
                },
              },
            },
          },
        },
      },
    },
    "& .open-nav": {
      transition: "all 0.3s ease-in-out",
      top: "74px !important",
      height: "100vh",
      overflow: "hidden",
      "& .socials": {
        display: "flex !important",
        gap: "30px",
        position: "fixed",
        bottom: "24px",
      },
    },
  },
}));
