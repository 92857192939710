import { makeStyles } from "@mui/styles";
import { colors } from "../../../styles/colors";
import card1bg from "../../../assets/community/card1-bg.svg";
import card2bg from "../../../assets/community/card2-bg.svg";
import img from "../../../assets/send/card2-img.jpg";

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: "108px 0px",
    fontFamily: "'Monserrat', sans-serif",
    "& .wrapper": {
      display: "grid",
      gridTemplateColumns: "1.2fr 0.8fr",
      gap: "64px",
      alignItems: "center",

      [theme.breakpoints.down("md")]: {
        gridTemplateColumns: "1fr",
        maxWidth: "100%",
      },
      "& h1": {
        fontWeight: 700,
        fontSize: "36px",
        lineHeight: "45px",
        color: "#1D2027",
        marginBottom: "40px",
        [theme.breakpoints.down("sm")]: {
          textAlign: "center",
          fontSize: "26px",
        },
        "& span": {
          color: colors.doyarPeach,
        },
      },
      "& p.intro": {
        display: "none",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "28px",
        color: "#30415A",
        [theme.breakpoints.down("sm")]: {
          textAlign: "center",
          display: "block",
          marginBottom: "16px",
        },
      },
      "& svg path": {
        fill: "#14B078",
      },
      "&  svg.coming path": {
        fill: "#637490 !important",
      },
      "& .features": {
        display: "flex",
        flexDirection: "column",
        gap: "28px",
        "& .feature": {
          display: "flex",
          gap: "18px",
          "& span": {
            fontWeight: 500,
            fontSize: "20px",
            lineHeight: "34px",
            color: colors.doyarDark,

            [theme.breakpoints.down("sm")]: {
              fontSize: "16px",
            },
          },
          "&.active": {
            animation: "all 2s ease-in-out",
            "& span": {
              color: colors.doyarPeach + " !important",
            },
            "&:not(.coming) svg path": {
              fill: colors.doyarPeach,
            },
          },
        },
      },
      "& .left": {
        maxWidth: "483px",
        marginLeft: "100px",
        [theme.breakpoints.down("md")]: {
          marginLeft: "0px",
          maxWidth: "100%",
        },
      },
      "& .right": {
        position: "relative",
        width: "100%",
        "&.mobile": {
          display: "none",
          [theme.breakpoints.down("md")]: {
            display: "block",
          },
        },
        "&.desktop": {
          display: "block",
          [theme.breakpoints.down("md")]: {
            display: "none",
          },
        },
        "& .right__img_container": {
          overflow: "hidden",
          position: "absolute",
          height: "449px",
          width: "417.6px",
          top: "-25px",
          left: "25px",
          borderRadius: "20px",
          [theme.breakpoints.down("md")]: {
            width: "90%",
            left: "5%",
          },

          "& .right__img": {
            width: "100%",
            height: "100%",
            backgroundColor: "#fff",
            backgroundImage: `url(${img})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            "&:hover,:focus": {
              transition: "all .4s ease-in-out",
              transform: "scale(1.1)",
            },
          },
        },
        "& .right__shadow": {
          background: "#F4F6F9",
          borderRadius: "20px",
          height: "449px",
          width: "417.6px",
          marginRight: "25px",
          // marginTop: "-32px",
          [theme.breakpoints.down("md")]: {
            width: "90%",
            marginRight: "5%",
          },
        },
      },
    },
  },
}));
