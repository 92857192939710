import React, { useState } from "react";
import { useStyles } from "./style";
import { IconClose, IconOpen } from "../../../assets/icons";
import { Link } from "react-router-dom";

const Faqs = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = (id) => {
    id === open ? setOpen(false) : setOpen(id);
  };
  const classes = useStyles();
  return (
    <div className={classes.container + " wrapper"}>
      <h1>FAQS</h1>
      <div className="faqs">
        <div
          className={open === 0 ? "faq opened" : "faq"}
          onClick={() => handleOpen(0)}
        >
          <div className="question">
            <h3>What is Doyar Community?</h3>
            <IconOpen className={"icon"} />
          </div>
          <div className="answer">
            <p>
              Doyar Community is a space where individuals and businesses come
              together to share experiences, build wealth and get support on the
              benefits of non-interest Banking.
            </p>
          </div>
        </div>
        <div
          className={open === 1 ? "faq opened" : "faq"}
          onClick={() => handleOpen(1)}
        >
          <div className="question">
            <h3>Who is eligible to apply?</h3>
            <IconOpen className={"icon"} />
          </div>
          <div className="answer">
            <p>
              Doyar Community is a space where individuals and businesses come
              together to share experiences, build wealth and get support on the
              benefits of non-interest Banking.
            </p>
          </div>
        </div>
      </div>
      <h1>Still have more questions?</h1>
      <p className="info">
        <a href={"/contact"}>Get In Touch</a> with our customer care centre
      </p>
    </div>
  );
};

export default Faqs;
