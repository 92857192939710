import React, { useState, useEffect } from "react";
import { useStyles } from "./style";
import { CheckSquare } from "../../../assets/icons";
import { colors } from "../../../styles/colors";
import { HistorySharp } from "@mui/icons-material";

const Features = () => {
  const [currentText, setCurrentText] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentText((prevSlide) => (prevSlide + 1) % 4);
    }, 2000); // Adjust the interval time (in milliseconds) as needed

    return () => clearInterval(interval);
  }, [currentText]);
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className="wrapper">
        <div className="left">
          <h1
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="1400"
          >
            We have the Best App for all your Transfers
          </h1>
          <p className="intro">
            Banking with exceptional value for your money, built on trust and
            transparency. Your success is our top priority.
          </p>
          <div className="features">
            <div className={currentText === 0 ? "feature active" : "feature"}>
              <CheckSquare /> <span>Fast</span>
            </div>
            <div className={currentText === 1 ? "feature active" : "feature"}>
              <CheckSquare /> <span>Secured</span>
            </div>
            <div className={currentText === 2 ? "feature active" : "feature"}>
              <CheckSquare /> <span>Smart </span>
            </div>
            <div className={currentText === 3 ? "feature active" : "feature"}>
              <CheckSquare /> <span>Statements</span>
            </div>
          </div>
          <div
            style={{
              marginTop: "26px",
              display: "flex",
              alignItems: "center",
              gap: "4px",
              color: colors.doyarAsh + " !important",
            }}
          >
            <p
              style={{
                fontSize: "16px !important",
                color: colors.doyarAsh,
              }}
            >
              Coming Soon...{" "}
            </p>
            <HistorySharp className="coming" />
          </div>
        </div>
        <div
          className="right desktop"
          data-aos="fade-left"
          data-aos-easing="linear"
          data-aos-duration="1400"
        >
          <div className="right__img_container ">
            <div className="right__img"></div>
          </div>
          <div className="right__shadow"></div>
        </div>
        <div className="right mobile">
          <div className="right__img_container ">
            <div className="right__img"></div>
          </div>
          <div className="right__shadow"></div>
        </div>
      </div>
    </div>
  );
};

export default Features;
