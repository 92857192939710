import React, { useEffect } from "react";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import Hero from "../../components/homepage/hero";
import Info from "../../components/homepage/info";
import Intro from "../../components/homepage/intro";
import Community from "../../components/homepage/community";
import JoinBetter from "../../components/general/join-better";

const Homepage = () => {
  //add meta title and description
  useEffect(() => {
    document.title = "My Doyar";
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "Hello, we' re Doyar. The benefits of non-interest banking has been limited to a few. So we decided to share it with you. All on your phone."
      );
    document.body.classList.remove("disable-scroll");
  }, []);
  return (
    <>
      <Navbar />
      <Hero />
      <Info />
      <Intro />
      <Community />
      <JoinBetter />

      <Footer />
    </>
  );
};

export default Homepage;
