import React from "react";
import { useStyles } from "./intro.style";
import phone1 from "../../../assets/homepage/phone1.webp";
import phone2 from "../../../assets/homepage/phone2.webp";
import ButtonP from "../../buttons/primary";
import ButtonS from "../../buttons/secondary";
import { colors } from "../../../styles/colors";

const Intro = () => {
  const classes = useStyles();
  return (
    <div className={classes.container + " wrapper"}>
      <div className="grid">
        <img
          src={phone1}
          alt="phone1"
          data-aos="fade-up"
          data-aos-easing="linear"
          data-aos-duration="1000"
        />
        <div className="text">
          {/* <ScrollAnimation animateIn="wobble"> */}
          <h6
            className=""
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="1000"
          >
            Introducing Doyar Bank App{" "}
          </h6>
          {/* </ScrollAnimation> */}
          <div
            data-aos="fade-zoom-in"
            // data-aos-easing="ease-in-back"
            data-aos-duration="2000"
          >
            {" "}
            <p

            // data-aos-offset="0"
            >
              We are building the best platform for ethical savings, investment
              and smart payments.{" "}
              <span>Coming soon on your device stores.</span>
            </p>
            <ButtonP to="/waitlist">
              Join Waitlist
            </ButtonP>
          </div>
        </div>
      </div>
      <div className="grid">
        <div className="text">
          <h6
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="1000"
          >
            For the Future{" "}
          </h6>
          <div
            data-aos="fade-zoom-in"
            // data-aos-easing="ease-in-back"
            data-aos-duration="2000"
          >
            <p>
              It is time to step into the future. Our application helps you
              ethically manage your money. The Doyar application is carefully
              design to make non-interest banking easy and intuitive for
              everyone.
            </p>
            <ButtonS
              borderColor={"transparent"}
              fontColor={colors.doyarPeach}
              paddingLeft={"0"}
              to="/about"
            >
              Learn More
            </ButtonS>
          </div>
        </div>
        <img
          src={phone2}
          alt="phone2"
          data-aos="fade-up"
          data-aos-easing="linear"
          data-aos-duration="1000"
        />
      </div>
    </div>
  );
};

export default Intro;
