export const colors = {
  doyarBlue: "#0B98E7",
  doyarDark: "#1B2D4A",
  doyarPeach: "#ED6669",
  darkGray: "#252C32",
  doyarAsh: "#637490",
  gray2: "#4F4F4F",
  label: ":#344054",
  white: "#FFFFFF",
  black: "#000000",
  background: "#F4F8F9",
  secondary: "#30415A",
};
