import React from "react";
import { useStyles } from "./style";
import ButtonS from "../../buttons/secondary";
import {
  CommunityIcon3,
  CommunityIcon4,
  ArrowRight,
} from "../../../assets/icons";
import { Link } from "react-scroll";

const Influence = () => {
  const classes = useStyles();
  return (
    <div className={classes.container + " wrapper"}>
      <div className="cards">
        <div className="card card1">
          <div className="header">
            <CommunityIcon3 /> <h6>Student Ambassadors</h6>
          </div>
          <p>
            Our ambassadors serves as the face of Doyar on campus. Acting as
            vital part in our strategy of bringing the value of non-interest
            banking to everyone.
          </p>
          <Link to="ambassadors">
            <u>Learn More</u>
            <ArrowRight className="arrow" />
          </Link>
        </div>
        <div className="card card2">
          <div className="header">
            <CommunityIcon4 /> <h6>Regional Ambassadors</h6>
          </div>
          <p>
            Regional ambassadors play a crucial role in connecting diverse
            communities with our innovative financial solutions for positive
            financial change within their regions.
          </p>
          <Link to="ambassadors">
            <u>Learn More</u>
            <ArrowRight className="arrow" />
          </Link>
        </div>
      </div>
      <div className="pulse">
        <div className="left">
          <h1
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="1400"
          >
            Create a Beneficial <br />
            <span>Influence</span> &amp; <span>Career Skill</span>
          </h1>
          <p
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="1400"
          >
            Making a lasting impact by actively networking, collaborating, and
            enhancing your skills through tasks with peers and individuals in
            your local community.
          </p>
        </div>
        <div
          className="right desktop"
          data-aos="fade-left"
          data-aos-easing="linear"
          data-aos-duration="1400"
        >
          <div className="right__img_container ">
            <div className="right__img"></div>
          </div>
          <div className="right__shadow"></div>
        </div>
        <div className="right mobile">
          <div className="right__img_container ">
            <div className="right__img"></div>
          </div>
          <div className="right__shadow"></div>
        </div>
      </div>
    </div>
  );
};

export default Influence;
