import { makeStyles } from "@mui/styles";
import { colors } from "../../../styles/colors";
import bg from "../../../assets/waitlist/waitlist-background.webp";
import bg2 from "../../../assets/waitlist/inner-background.svg";
import img from "../../../assets/waitlist/waitlist-img.jpg";

export const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: "#0D0D0D",
        backgroundImage: `linear-gradient(rgba(13, 13, 13, 0.5), rgba(13, 13, 13, 0.5)), url(${bg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        minHeight: "100vh",
        padding: "44px 0px",

        "& .top-bar":{
            display:"flex",
            justifyContent:"space-between",
            alignItems:"center",
            color:colors.white,
            marginBottom:"31px",
            position:"relative",
            [theme.breakpoints.down('md')]:{
                '& img': {
                    height: "24px",
                },
            },
            "& .top-bar-items":{
                display:"flex",
                gap:"23px",
                padding:"8px",
                background:"#141516",
                border:"1px solid #18181714",
                borderRadius:"30px",
                "& .close-icon": {
                    display: "none",
                },
                    
                [theme.breakpoints.down('md')]:{
                    display:"none",
                },
                
                "& button.top-bar-item":{
                    padding:"4px 18px",
                    background:"#7C787814",
                    color:colors.white,
                    borderRadius:"30px",
                    border:"1px solid #18181714",
                    fontFamily:"Manrope",
                    fontSiz:"13px",
                    fontWeight:"400",
                    outline:"none",
                    cursor:"pointer",   
                },
             },
             "& .empty": {
                    [theme.breakpoints.down('md')]:{
                        display:"none",
                    },
                },
                "& .mobile-menu": {
                    display:"none",
                    [theme.breakpoints.down('md')]:{
                        display:"block",
                        background:"transparent",
                        border:"none",
                        outline:"none",
                    },
                },
        },
        "& .inner-content":{
            background: `url(${bg2})`,
            backgroundSize: "contain",
            backgroundRepeat:"no-repeat",
            padding:"47px 54px",
            display:"grid",
            gridTemplateColumns:"1fr 1fr",
            gap:"75px",
            borderRadius:"13px",
            border:"1px solid #414040",
            [theme.breakpoints.down('md')]:{
                display:"flex",
                flexDirection:"column",
                gap:"20px",
                padding:"16px",
                backgroundSize: "cover",
                width:"100%",
            },
            "& .img-container":{
                background: `url(${img})`,
                backgroundSize: "cover",
                backgroundRepeat:"no-repeat",
                height:"624px",
                width:"100%",
                borderRadius:"20px",
                [theme.breakpoints.down('md')]:{
                    order:2,
                    width:"100%",
                    backgroundSize: "cover",
                    height:"300px",
                },
                "& .icons":{
                    display:"flex",
                    // justifyContent:'center',
                    alignItems:"center",
                    width:'max-content',
                    gap:"20px",
                    margin:"26px auto",
                    borderRadius:"34px",
                    backgroundColor:"#141516",
                    padding:"12px 9px",
                    [theme.breakpoints.down('md')]:{
                        justifyContent:'center',
                        gap:"10px",
                        padding:"10px 5px",
                    },
                    "& a":{
                        display:"block",
                        textDecoration:"none",
                        display:"flex",
                        // justifyContent:'center',
                        alignItems:"center",
                    }
                }
            },
            "& .content":{
              
                "& h2":{
                    fontFamily:"Roboto",
                    fontSize:"48px",
                    fontWeight:"900",
                    color:colors.white,
                    "& span":{
                        color:colors.doyarPeach
                    },
                    [theme.breakpoints.down('md')]:{
                        fontSize:"29px",
                    },

                },
               "& .direction-wrapper": {
                    display: "flex",
                    alignItems: "center", 
                    gap: "10px", 
                },
                "& .arrow-container": {
                    display: "flex",
                    alignItems: "center", // Centers the arrow vertically within the container
                    "& .arrow": {
                        width: "0",
                        height: "5px",
                        backgroundColor: colors.doyarPeach,
                        position: "relative",
                        transition: "width 0.5s ease",
                        animation: "$expandAndRetract 6s ease-in-out infinite",
                        "&::after": {
                            content: '""',
                            position: "absolute",
                            top: "50%",
                            transform: "translateY(-50%)",
                            borderTop: "5px solid transparent",
                            borderBottom: "5px solid transparent",
                            borderLeft: "10px solid " + colors.doyarPeach,
                            right: "-10px",
                        },
                    },
                },
                "& p":{
                    fontFamily:"Montserrat",
                    fontSize:"18px",
                    fontWeight:"400",
                    color:colors.white,
                    "& .hashtag":{
                        color:colors.doyarPeach
                    },
                    [theme.breakpoints.down('md')]:{
                        fontSize:"10px",
                    },
                   
                },
                "& .counter-container":{
                    display:"flex",
                    justifyContent:"center",
                    alignItems:"center",
                    gap:"10px",
                    marginTop:"61px",
                    marginBottom:"50px",
                    [theme.breakpoints.down('md')]:{
                        gap:"5px",
                        marginTop:"30px",
                        marginBottom:"30px",
                    },
                    "& .time-unit":{
                        display:"flex",
                        flexDirection:"column",
                        justifyContent:"center",
                        alignItems:"center",
                        gap:"11px",
                        [theme.breakpoints.down('md')]:{
                            gap:"5px",
                        },
                        "& .unit":{
                            display:"flex",
                            alignItems:"center",
                            gap:"9px",
                            [theme.breakpoints.down('md')]:{
                                gap:"5px",
                            },
                            "& h6":{
                                background:"#1C1D1E",
                                padding:"5px 10px",
                                borderRadius:"10px",
                                border:"1.15px solid #141516",
                                fontFamily:"Roboto",
                                fontSize:"35px",
                                fontWeight:"700",
                                color:colors.white,
                                [theme.breakpoints.down('md')]:{
                                    fontSize:"20px",
                                    padding:"2.5px 5px",
                                },
                            }
                        },
                        "& span":{
                            fontFamily:"Manrope",
                            fontSize:"13px",
                            fontWeight:"400",
                            color:colors.white,
                            [theme.breakpoints.down('md')]:{
                                fontSize:"8px",
                            },

                        }

                    },
                    "& .divider":{
                        fontFamily:"Roboto",
                        fontSize:"35px",
                        fontWeight:"700",
                        color:colors.white,
                        [theme.breakpoints.down('md')]:{
                            fontSize:"20px",
                        },
                    }
                },
                "& .button-container":{
                    display:"flex",
                    justifyContent:"space-between",
                    alignItems:"center",
                    gap:"20px",
                    backgroundColor:"#141516",
                    borderRadius:"100px",
                    padding:"8px",
                    marginTop:"42px",
                    [theme.breakpoints.down('md')]:{
                        gap:"10px",
                        padding:"5px",
                    },
                    "& span":{
                        fontFamily:"Montserrat",
                        fontSize:"14px",
                        fontWeight:"400",
                        color:colors.white,
                        marginLeft:"12px",
                        [theme.breakpoints.down('md')]:{
                            fontSize:"10px",
                        },
                    },
                    "& button":{
                        padding:"10px 35px",
                        background:colors.doyarDark,
                        borderRadius:"100px",
                        border:"none",
                        outline:"none",
                        fontFamily:"Manrope",
                        fontSize:"14px",
                        fontWeight:"600",
                        color:colors.white,
                        cursor:"pointer",
                        [theme.breakpoints.down('md')]:{
                            padding:"5px 20px",
                            fontSize:"10px",
                        },
                    }
                }
            },
        }
    },
   '@keyframes expandAndRetract': {
       '0%':{
          width: '0',
          right:' -10px',
        },
       '50%' :{
          width:'100px',
          right: '0',
        },
        '100%': {
          width: '0',
          right: '-10px',
        }
    },
    openMobileMenu: {
        display: "flex !important",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "0 !important",
        gap: "23px",
        padding: "8px",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "#130F2633 !important",
        backdropFilter: "blur(50px)",
        zIndex: 999,
        overflow: "hidden",
        //display close icon
        "& .close-icon": {
            display: "block !important",
            position: "absolute",
            top: "50px",
            right: "50px",
            outline: "none",
            border: "none",
            background: "none",
            cursor: "pointer",
            
        },
        
    },

    modal: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "#130F2633",
        backdropFilter: "blur(5px)",
        zIndex: 1000,
        overflowY: "auto",
    },
    modalContent: {
        backgroundColor: '#1C1D1E',
        padding: "23px",
        borderRadius: "13px",
        maxWidth: "526px",
        width: "90%",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        border:"1px solid #414040",
        maxHeight: "90%",
        overflowY: "auto",
        "& .header":{
            display:"flex",
            justifyContent:"space-between",
            alignItems:"center",
            width:"100%",
            "& .close-icon":{
                outline:"none",
                border:"none",
                background:"none",
                cursor:"pointer",
            }
        },
        "& h2": {
            fontFamily: "Manrope",
            fontSize: "18px",
            fontWeight: 700,
            color: colors.white,
        },
        "& p": {
            fontFamily: "Montserrat",
            fontSize: "14px",
            fontWeight: 400,
            color: colors.white,
           
        },
        "& a":{
            color:colors.doyarPeach,
            textDecoration:"none",
        },
        "& br": {
            lineHeight:'150%'

        },
        "& .inner": {
            backgroundColor: "#0D0D0D",
            padding: "23px",
            borderRadius: "13px",
            "& h2":{
                color:colors.doyarPeach,
                marginBottom:"13px",

            }
        },
       
    },
    
}))