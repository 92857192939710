import { makeStyles } from "@mui/styles";
import { colors } from "../../../styles/colors";
import bg from "../../../assets/contact/hero-bg.svg";

export const useStyles = makeStyles((theme) => ({
  container: {
    // paddingBottom: "97px",
    fontFamily: "'Montserrat', sans-serif",

    "& .banner ": {
      background: `url(${bg}) no-repeat center`,
      backgroundSize: "cover",
      fontFamily: "'Montserrat', sans-serif",

      "& .wrapper": {
        display: "flex",
        alignItems: "center",
        paddingTop: "130px",
        flexDirection: "column",

        [theme.breakpoints.down("md")]: {
          paddingTop: "80px",

          "& img": {
            width: "50%",
            height: "auto",
          },
        },

        "& h1": {
          color: "#181817",
          fontSize: "44px",
          fontWeight: 700,
          lineHeight: "55px",
          marginBottom: "16px",
          textAlign: "center",

          [theme.breakpoints.down("sm")]: {
            fontSize: "30px",
          },
        },
      },
    },
    "& .card-group.wrapper": {
      padding: "52px 0",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      "& p.intro": {
        color: colors.doyarDark,
        fontSize: "18px",
        fontWeight: 500,
        lineHeight: "28px",
        textAlign: "center",
        marginBottom: "76px",
        maxWidth: "652px",
      },
      "& .cards": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "80px",
        [theme.breakpoints.down("md")]: {
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
        },
        [theme.breakpoints.down("sm")]: {
          gap: "40px",
          gridTemplateColumns: "1fr",
        },
        "& .card": {
          border: "1px solid " + colors.doyarAsh,
          borderRadius: "20px",
          padding: "16px",
          maxWidth: "298px",
          height: "311px",
          position: "relative",
          "&:hover": {
            background: colors.doyarDark,

            transition: "all 0.3s ease",
            "& h6, p": {
              color: "#fff !important",
            },
            "& svg": {
              "& circle": {
                fill: colors.doyarAsh,
              },
              "& path": {
                fill: "#fff",
              },
            },
          },

          [theme.breakpoints.down("sm")]: {
            maxWidth: "100%",
            height: "auto",
          },
          "& .content": {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          },
          "& h6": {
            color: "#181817",
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "20px",
            margin: "18px 0",
          },
          "& p": {
            color: "#30415A",
            fontSize: "16px",
            fontWeight: 500,
            lineHeight: "28px",
            // marginBottom: "33px",
          },
          "& a": {
            position: "absolute",
            display: "block",
            textDecoration: "none",
            color: colors.doyarPeach,
            fontSize: "18px",
            fontWeight: 500,
            lineHeight: "22px",
            bottom: "16px",
            // marginTop: "33px",
            // text,
            [theme.breakpoints.down("md")]: {
              position: "static",
              marginTop: "33px",
            },
          },
        },
      },
    },
  },
}));
