import React, { useEffect } from "react";
import Navbar from "../../components/navbar";
import Hero from "../../components/community/hero";
import Footer from "../../components/footer";
import Influence from "../../components/community/influence";
import Perks from "../../components/community/perks";
import Ambassadors from "../../components/community/ambassadors";
import Faqs from "../../components/community/faqs";
import JoinBetter from "../../components/general/join-better";

const Community = () => {
  //add meta title and description
  useEffect(() => {
    document.title = "Our Community | My Doyar";
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "Hello, we' re Doyar. The benefits of non-interest banking has been limited to a few. So we decided to share it with you. All on your phone."
      );
    document.body.classList.remove("disable-scroll");
  }, []);
  return (
    <>
      <Navbar />
      <Hero />
      <Influence />
      <Perks />
      <Ambassadors />
      <Faqs />
      <JoinBetter />
      <Footer />
    </>
  );
};

export default Community;
