import { makeStyles } from "@mui/styles";
import { colors } from "../../../styles/colors";
import card1bg from "../../../assets/community/card1-bg.svg";
import card2bg from "../../../assets/community/card2-bg.svg";
import pulse from "../../../assets/community/pulse-bg.jpg";

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: "108px 0px",
    fontFamily: "'Monserrat', sans-serif",
    "& .cards": {
      display: "grid",
      gridTemplateColumns: "repeat(2, 1fr)",
      gap: "96px",
      [theme.breakpoints.down("md")]: {
        gap: "40px",
      },
      [theme.breakpoints.down("md")]: {
        gap: "30px",
        gridTemplateColumns: "1fr",
      },

      "& .card": {
        padding: "26px",
        borderRadius: "20px",
        "& .header": {
          display: "flex",
          alignItems: "center",
          gap: "16px",
          marginBottom: "16px",
          "& h6": {
            fontSize: "18px",
            lineHeight: "34px",
            fontWeight: 600,
            color: "#30415A",
          },
        },
        "& p": {
          fontSize: "16px",
          lineHeight: "28px",
          fontWeight: 400,
          color: "#30415A",
        },
        "& a": {
          textDecoration: "none",
          display: "flex",
          fontSize: "16px",
          lineHeight: "20px",
          fontWeight: 500,
          color: colors.doyarPeach,
          gap: "8px",
          marginTop: "32px",
          cursor: "pointer",
          "& .arrow": {
            transition: "all 0.5s ease-in-out",
            opacity: 0,
          },
          "&:hover": {
            "& .arrow": {
              opacity: 1,
            },
          },
        },
        "&.card1": {
          background: `url(${card1bg})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        },
        "&.card2": {
          background: `url(${card2bg})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        },
      },
    },
    "& .pulse": {
      marginTop: "108px",
      fontFamily: "'Montserrat', sans-serif",
      display: "grid",
      gridTemplateColumns: "1.2fr 0.8fr",
      gap: "64px",
      alignItems: "center",

      [theme.breakpoints.down("md")]: {
        gridTemplateColumns: "1fr",
        maxWidth: "100%",
      },
      "& h1": {
        fontWeight: 700,
        fontSize: "36px",
        lineHeight: "45px",
        color: "#1D2027",
        marginBottom: "40px",
        [theme.breakpoints.down("sm")]: {
          textAlign: "center",
          fontSize: "26px",
        },
        "& span": {
          color: colors.doyarPeach,
        },
      },
      "& p": {
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "28px",
        color: "#30415A",
        [theme.breakpoints.down("sm")]: {
          textAlign: "center",
        },
      },
      "& .left": {
        maxWidth: "554px",
        [theme.breakpoints.down("md")]: {
          maxWidth: "100%",
        },
      },
      "& .right": {
        position: "relative",
        width: "100%",
        "&.mobile": {
          display: "none",
          [theme.breakpoints.down("md")]: {
            display: "block",
          },
        },
        "&.desktop": {
          display: "block",
          [theme.breakpoints.down("md")]: {
            display: "none",
          },
        },
        "& .right__img_container": {
          overflow: "hidden",
          position: "absolute",
          height: "341px",
          width: "414px",
          top: "-25px",
          left: "25px",
          borderRadius: "20px",
          [theme.breakpoints.down("md")]: {
            width: "90%",
            left: "5%",
          },

          "& .right__img": {
            width: "100%",
            height: "100%",
            backgroundColor: "#fff",
            backgroundImage: `url(${pulse})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            "&:hover,:focus": {
              transition: "all .4s ease-in-out",
              transform: "scale(1.1)  rotate(5deg)",
            },
          },
        },
        "& .right__shadow": {
          background: "#F4F6F9",
          borderRadius: "20px",
          height: "341px",
          width: "414px",
          marginRight: "25px",
          // marginTop: "-32px",
          [theme.breakpoints.down("md")]: {
            width: "90%",
            marginRight: "5%",
          },
        },
      },
    },
  },
}));
