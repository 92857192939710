import React from "react";
import { Link } from "react-router-dom";
import { useStyles } from "./buttons.style";
import Arrow from "@mui/icons-material/ArrowForward";

const ButtonS = (props) => {
  const {
    a,
    to,
    fontColor,
    bgColor,
    borderColor,
    paddingLeft,
    dataAos,
    dataAosEasing,
    dataAosDuration,
  } = props;
  const classes = useStyles();
  return a ? (
    <a
      className={classes.container + " secondary"}
      style={{
        color: fontColor,
        backgroundColor: bgColor,
        borderColor: borderColor,
        paddingLeft: paddingLeft,
      }}
      href={to}
      target="_blank"
      rel="noreferrer"
      data-aos={dataAos}
      data-aos-easing={dataAosEasing}
      data-aos-duration={dataAosDuration}
    >
      {props.children} <Arrow fontSize="14px" />
    </a>
  ) : (
    <Link
      className={classes.container + " secondary"}
      style={{
        color: fontColor,
        backgroundColor: bgColor,
        borderColor: borderColor,
        paddingLeft: paddingLeft,
      }}
      to={to}
      data-aos={dataAos}
      data-aos-easing={dataAosEasing}
      data-aos-duration={dataAosDuration}
    >
      {props.children} <Arrow fontSize="14px" />
    </Link>
  );
};

export default ButtonS;
