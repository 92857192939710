import React, { useEffect } from "react";
import Content from "../../components/waitlist/content";


const Waitlist = () => {
  //add meta title and description
  useEffect(() => {
    document.title = "Waitlist | My Doyar";
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "Hello, we' re Doyar. The benefits of non-interest banking has been limited to a few. So we decided to share it with you. All on your phone."
      );
    document.body.classList.remove("disable-scroll");
  }, []);
  return (
    <>
      <Content/>
    </>
  );
};

export default Waitlist;
