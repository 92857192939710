import React from "react";
import { useStyles } from "./style";

const Aspiration = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className="wrapper">
        <h1>
          Turn Aspirations into <span>Reality</span>
          <p>
            Setting goals, saving smart, and making dreams a reality. It all
            starts here
          </p>
        </h1>
      </div>
      <div className="running-texts one">
        <h6 className="outline">VACATION IN MAURITIUS</h6>
        <h6>MONEY FOR STARLINK</h6>
        <h6 className="outline">M1 GOALS</h6>
        <h6>FORD TAURUS 2019</h6>
      </div>
      <div className="running-texts two">
        <h6 className="outline">VACATION IN MAURITIUS</h6>
        <h6>MONEY FOR STARLINK</h6>
        <h6 className="outline">M1 GOALS</h6>
        <h6>FORD TAURUS 2019</h6>
      </div>
      <div className="running-texts three">
        <h6 className="outline">VACATION IN MAURITIUS</h6>
        <h6>MONEY FOR STARLINK</h6>
        <h6 className="outline">M1 GOALS</h6>
        <h6>FORD TAURUS 2019</h6>
      </div>
      <div className="running-texts four">
        <h6 className="outline">VACATION IN MAURITIUS</h6>
        <h6>MONEY FOR STARLINK</h6>
        <h6 className="outline">M1 GOALS</h6>
        <h6>FORD TAURUS 2019</h6>
      </div>
    </div>
  );
};

export default Aspiration;
