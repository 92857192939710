import { makeStyles } from "@mui/styles";
import bg from "../../../assets/homepage/hero-bg.svg";
import buildings from "../../../assets/homepage/buildings.webp";
import { colors } from "../../../styles/colors";

export const useStyles = makeStyles((theme) => ({
  container: {
    background: `url(${bg})`,
    paddingBottom: "97px",
    height: "90vh",
    width: "100%",
    overflow: "hidden",

    [theme.breakpoints.down("md")]: {
      height: "auto",
      paddingBottom: "26px",
    },
    "& .wrapper": {
      paddingTop: "150px",
      [theme.breakpoints.down("md")]: {
        paddingTop: "24px",
      },
      "& h1": {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: "36px",
        lineHeight: "43.88px",
        fontWeight: "700",
        color: "#181817",
        marginBottom: "21px",
        "& span": {
          color: colors.doyarPeach,
          [theme.breakpoints.down("sm")]: {
            color: "#181817",
          },
        },
        [theme.breakpoints.down("md")]: {
          fontSize: "28px",
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: "24px",
          lineHeight: "30px",
        },
      },
      "& p": {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: "18px",
        lineHeight: "28px",
        fontWeight: "500",
        color: colors.doyarDark,
        marginBottom: "46px",
        [theme.breakpoints.down("sm")]: {
          fontSize: "12px",
          lineHeight: "18px",
          textAlign: "center",
        },
      },
      "& .cards": {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        alignItems: "center",
        gap: "49px",
        padding: "41px 46px",
        backgroundColor: "#fff",
        borderRadius: "30px",
        [theme.breakpoints.down("md")]: {
          gridTemplateColumns: "1fr",
          padding: "26px 16px",
          borderRadius: "10px",
        },

        "& .buttons": {
          display: "flex",
          flexDirection: "row",
          gap: "19px",
          [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            gap: "13px",
          },
        },
        "& .card-right": {
          backgroundColor: "#F4F6F9",
          backgroundImage: `url(${buildings})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          animation: "$moveBackground 5s linear infinite",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "30px",
          gap: "10px",
          borderRadius: "20px",
          [theme.breakpoints.down("sm")]: {
            height: "auto",
          },
          "& .right": {
            animation: "$moveRightLeft 7s linear infinite",
            [theme.breakpoints.down("sm")]: {
              height: "30px",
            },
          },
          "& .left": {
            animation: "$moveLeftRight 7s linear infinite",
            [theme.breakpoints.down("sm")]: {
              height: "94px",
            },
          },
        },
      },
    },
  },
  "@keyframes moveBackground": {
    "0%": {
      backgroundPosition: "0 100%",
    },
    "50%": {
      backgroundPosition: "100% 0",
    },
    "100%": {
      backgroundPosition: "0 100%",
    },
  },
  "@keyframes moveRightLeft": {
    "0%": {
      transform: "translateX(-40px)",
    },
    "50%": {
      transform: "translateX(40px)",
    },
    "100%": {
      transform: "translateX(-40px)",
    },
  },
  "@keyframes moveLeftRight": {
    "0%": {
      transform: "translateX(40px)",
    },
    "50%": {
      transform: "translateX(-40px)",
    },
    "100%": {
      transform: "translateX(40px)",
    },
  },
}));
