import { makeStyles } from "@mui/styles";
import { colors } from "../../../styles/colors";

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: "60px 0",
    background: "#F4F6F9",
    fontFamily: "'Montserrat', sans-serif",
    "& .wrapper": {
      "& .header": {
        "& h6": {
          color: colors.doyarPeach,
          fontWeight: 600,
          fontSize: "18px",
          lineHeight: "22px",
          [theme.breakpoints.down("sm")]: {
            textAlign: "center",
          },
        },
        "& h1": {
          fontWeight: 700,
          fontSize: "36px",
          lineHeight: "50px",
          color: "#181817",
          [theme.breakpoints.down("sm")]: {
            textAlign: "center",
            fontSize: "24px",
          },
        },
      },
      "& .departments": {
        marginTop: "40px",
        display: "flex",
        flexDirection: "column",
        gap: "30px",
        [theme.breakpoints.down("sm")]: {
          gap: "20px",
        },
        "& .department": {
          "& .dept-title": {
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "17px",
            color: colors.doyarAsh,
          },
          "& .positions": {
            marginTop: "16px",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            "& .position": {
              borderRadius: "16px",
              background: "#fff",
              padding: "33px 46px",
              position: "relative",
              height: "100px",
              [theme.breakpoints.down("sm")]: {
                height: "60px",
                padding: "15px 20px",
              },
              "&.open": {
                height: "auto",
                "& .position-body": {
                  transform: "scaleY(1)",
                },
              },
              "& .position-header": {
                display: "grid",
                gridTemplateColumns: "1fr 1fr 0.1fr",
                [theme.breakpoints.down("sm")]: {
                  gridTemplateColumns: "1fr 0.1fr",
                },
                "& .position-title": {
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  "& h6": {
                    fontWeight: 600,
                    fontSize: "18px",
                    lineHeight: "22px",
                    color: "#181817",
                    [theme.breakpoints.down("sm")]: {
                      fontSize: "14px",
                      lineHeight: "17px",
                    },
                  },
                },
                "& .position-location": {
                  [theme.breakpoints.down("sm")]: {
                    display: "none",
                  },
                  "& div": {
                    width: "max-content",
                  },

                  "& span": {
                    display: "block",
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "17px",
                    color: colors.doyarAsh,
                    textAlign: "center",
                  },
                  "& h6": {
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "17px",
                    color: "#181817",
                    textAlign: "center",
                  },
                },
                "& .icon": {
                  cursor: "pointer",
                },
              },
              "& .position-body": {
                marginTop: "30px",
                borderTop: "0.5px solid #637490",
                display: "grid",
                gridTemplateColumns: "0.4fr 1fr",
                transition: "all 0.3s ease",
                transform: "scaleY(0)",
                [theme.breakpoints.down("sm")]: {
                  gridTemplateColumns: "1fr",
                },

                "& .position-left": {
                  borderRight: "0.5px solid #637490",
                  [theme.breakpoints.down("sm")]: {
                    borderRight: "none",
                    "& img": {
                      height: "27px",
                    },
                  },
                  "& h5": {
                    fontWeight: 500,
                    fontSize: "30px",
                    lineHeight: "46px",
                    color: "#181817",
                    marginBottom: "60px",
                    [theme.breakpoints.down("sm")]: {
                      fontSize: "24px",
                      marginBottom: "30px",
                    },
                  },
                  "& h6": {
                    fontWeight: 500,
                    fontSize: "18px",
                    lineHeight: "22px",
                    color: "#181817",
                  },
                  "& span": {
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "19px",
                    color: colors.doyarAsh,
                  },
                  "& div": {
                    marginTop: "40px",
                    padding: "20px 0",
                    borderBottom: "0.5px solid #637490",
                    [theme.breakpoints.down("sm")]: {
                      padding: "10px 0",
                    },
                  },
                },
                "& .position-right": {
                  padding: "30px",
                  [theme.breakpoints.down("sm")]: {
                    padding: "0",
                  },
                  "& h6": {
                    fontWeight: 600,
                    fontSize: "18px",
                    lineHeight: "34px",
                    color: colors.doyarDark,
                    textDecoration: "underline",
                    marginTop: "30px",
                    marginBottom: "13px",
                  },
                  "& p, & ul li, & ol li": {
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "34px",
                    color: colors.doyarDark,
                  },
                  "& .note": {
                    padding: "18px 26px",
                    background: "#F4F6F9",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "34px",
                    color: colors.doyarDark,
                    fontStyle: "italic",
                    margin: "26px 0",
                    borderRadius: "20px",
                    "& span": {
                      fontWeight: 600,
                    },
                  },
                  "& a.apply": {
                    textDecoration: "none",
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                    padding: "15px 30px",
                    background: colors.doyarDark,
                    borderRadius: "10px",
                    fontWeight: 600,
                    fontSize: "18px",
                    lineHeight: "22px",
                    color: "#fff",
                    border: "none",
                    cursor: "pointer",
                    marginTop: "30px",
                    width: "max-content",
                    marginLeft: "auto",
                    marginRight: "auto",
                  },
                },
              },
            },
          },
        },
      },
    },
  },
}));
