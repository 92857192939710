import { makeStyles } from "@mui/styles";
import { colors } from "../../../styles/colors";
import bg from "../../../assets/community/hero-bg.svg";

export const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: "58px",

    fontFamily: "'Montserrat', sans-serif",

    "& .wrapper": {
      display: "grid",
      gridTemplateColumns: ".9fr 1.2fr",
      alignItems: "center",
      paddingTop: "150px",
      gap: "16px",
      [theme.breakpoints.down("md")]: {
        paddingTop: "60px",
        gridTemplateColumns: "1fr",
      },

      "& h1": {
        color: "#181817",
        fontSize: "36px",
        fontWeight: 700,
        lineHeight: "50px",
        marginBottom: "16px",
        // maxWidth: "302px",
        "& span": {
          color: colors.doyarPeach,
        },
        [theme.breakpoints.down("md")]: {
          textAlign: "center",
        },

        [theme.breakpoints.down("sm")]: {
          fontSize: "30px",
        },
      },
      "& p": {
        color: "#30415A",
        fontSize: "18px",
        fontWeight: 400,
        lineHeight: "34px",
        marginBottom: "36px",
        [theme.breakpoints.down("md")]: {
          textAlign: "center",
        },

        [theme.breakpoints.down("sm")]: {
          fontSize: "16px",
        },
      },

      "& .left": {
        [theme.breakpoints.down("md")]: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          "& a": {
            display: "none",
          },
        },
      },
      "& .right": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",

        [theme.breakpoints.down("sm")]: {
          "& img": {
            height: "auto",
            width: "80%",
            alignSelf: "center !important",
          },
        },
        "& .slide": {
          position: "relative",
          display: "flex",
          justifyContent: "center",

          "& .card": {
            position: "absolute",
            display: "flex",
            alignItems: "center",
            gap: "7px",
            padding: "10px",
            borderRadius: "10px",
            background: "#fff",
            animation: "$upDownAnimation 2s infinite alternate",
            [theme.breakpoints.down("sm")]: {
              padding: "7px",
              borderRadius: "5px",
            },

            "& span": {
              fontFamily: "'Inter', sans-serif",
              color: "#30415A",
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "21px",
              [theme.breakpoints.down("sm")]: {
                fontSize: "6px",
                lineHeight: "11px",
              },
            },
          },

          "& .card.card1": {
            bottom: "62px",
            left: "-150px",
            [theme.breakpoints.down("sm")]: {
              left: "0",
            },
          },
          "& .card.card2": {
            top: "53px",
            right: "-100px",
            [theme.breakpoints.down("sm")]: {
              right: "0",
            },
          },

          "& img": {
            borderRadius: "16px",
            alignSelf: "center",
          },

          // "& .slide1__img": {
          //   background: `url(${slide1})`,
          //   backgroundSize: "cover",
          //   backgroundPosition: "center",
          //   backgroundRepeat: "no-repeat",
          //   borderRadius: "20px",
          //   height: "436px",
          //   width: "457.75px",
          //   alignSelf: "center",
          // },
        },
      },
    },
  },
  "@keyframes upDownAnimation": {
    "0%": {
      transform: "translateY(0)",
    },
    "100%": {
      transform: "translateY(-10px)",
    },
  },
  "@keyframes fadeInOut": {
    "0%": {
      opacity: 0.3,
    },
    "100%": {
      opacity: 1,
    },
  },
}));
