import React from "react";
import { useStyles } from "./style";
import cardImg from "../../../assets/send/card1-img.webp";
import ButtonS from "../../buttons/secondary";
import { colors } from "../../../styles/colors";

const Cards = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className="wrapper">
        <img
          className="desktop"
          src={cardImg}
          alt="hero-img"
          height={"555px"}
          data-aos="fade-right"
          data-aos-easing="linear"
          data-aos-duration="1000"
        />
        <img
          className="mobile"
          src={cardImg}
          alt="hero-img"
          height={"555px"}
          data-aos="zoom-in"
          data-aos-easing="linear"
          data-aos-duration="1000"
        />
        <div className="right">
          <h1
            data-aos="fade-right"
            data-aos-easing="linear"
            data-aos-duration="1000"
          >
            Save all your Cards in one Place for Easy Transactions
          </h1>
          <p
            data-aos="zoom-in"
            data-aos-easing="linear"
            data-aos-duration="1000"
          >
            Easily save all your cards for transactions on our secured app.
          </p>
          <ButtonS
            fontColor={"#fff"}
            bgColor={colors.doyarDark}
            dataAos="zoom-in"
            dataAosEasing="linear"
            dataAosDuration="1000"
            to="/waitlist"
          >
            Join Waitlist
          </ButtonS>
        </div>
      </div>
    </div>
  );
};

export default Cards;
