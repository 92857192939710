import { makeStyles } from "@mui/styles";
import { colors } from "../../../styles/colors";

export const useStyles = makeStyles((theme) => ({
  container: {
    background: colors.white,
    padding: "76px 0px",
    width: "100%",
    height: "100%",

    "& .wrapper": {
      "& .header": {
        "& h3": {
          fontSize: "44px",
          fontWeight: "bold",
          lineHeight: "50px",
          fontFamily: "'Montserrat', sans-serif",
          color: "#181817",
          marginBottom: "16px",
          textAlign: "center",
          [theme.breakpoints.down("md")]: {
            fontSize: "32px",
            lineHeight: "48px",
          },
          [theme.breakpoints.down("sm")]: {
            fontSize: "24px",
            lineHeight: "32px",
          },
        },
        "& p": {
          fontSize: "18px",
          lineHeight: "30px",
          color: colors.secondary,
          fontFamily: "'Inter', sans-serif",
          textAlign: "justify",
          [theme.breakpoints.down("sm")]: {
            fontSize: "16px",
            lineHeight: "24px",
          },
        },
      },
      "& .content": {
        marginTop: "129px",
        display: "grid",
        gridTemplateColumns: "1fr 2.1fr",
        // gap: "20px",

        [theme.breakpoints.down("sm")]: {
          gridTemplateColumns: "1fr",
          marginTop: "64px",
        },

        "& .links": {
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          [theme.breakpoints.down("sm")]: {
            display: "none",
          },

          "& a": {
            padding: "18px",
            fontSize: "18px",
            lineHeight: "30px",
            color: colors.secondary,
            fontFamily: "'Inter', sans-serif",
            textDecoration: "none",

            "&.active": {
              fontWeight: 600,
              background: "#F4F6F9",
              color: "#1B2D4A",
              borderLeft: "8px solid #1B2D4A",
            },
          },
        },
        "& .main-col": {
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          scrollBehavior: "smooth",
          overflowY: "scroll",
          height: "calc(100vh - 129px)",
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#1B2D4A",
            borderRadius: "6px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "#555",
          },
          "&::-webkit-scrollbar-track": {
            background: "#f1f1f1",
          },
          [theme.breakpoints.down("sm")]: {
            overflowY: "unset",
            height: "auto",
          },
          "& .col": {
            padding: "28px 35px",
            background: "#F4F6F9",

            "& h4": {
              fontSize: "24px",
              lineHeight: "30px",
              color: "#181817",
              fontFamily: "'Inter', sans-serif",
              margin: "30px 0px",
              [theme.breakpoints.down("sm")]: {
                fontSize: "18px",
                lineHeight: "24px",
              },
            },
            "& h6": {
              fontSize: "16px",
              lineHeight: "20px",
              color: "#181817",
              fontFamily: "'Inter', sans-serif",
              marginBottom: "16px",
              marginTop: "26px",
              [theme.breakpoints.down("sm")]: {
                fontSize: "14px",
                lineHeight: "18px",
              },
            },
            "& p, ul, li ": {
              fontSize: "18px",
              lineHeight: "30px",
              color: colors.secondary,
              fontFamily: "'Inter', sans-serif",
              textAlign: "justify",
              [theme.breakpoints.down("sm")]: {
                fontSize: "16px",
                lineHeight: "24px",
              },
            },
          },
        },
      },
    },
  },
}));
