import React from "react";
import { useStyles } from "./style";
import {
  SecurityIcon3,
  SecurityIcon4,
  SecurityIcon5,
  SecurityIcon6,
} from "../../../assets/icons";

const Philosophy = () => {
  const classes = useStyles();
  return (
    <div className={classes.container + " wrapper"}>
      <div className="header">
        <h4>Our Security Philosophy</h4>
        <h1>High-Level Security Meeting Regulations</h1>
        <p className="intro">
          Our ethical savings plans are rooted on the principal of non-interest
          banking. Your savings are invested in ethical , sustainable and
          secured financial instrument that guarantees a high profit.
        </p>
      </div>
      <div className="cards">
        <div>
          <SecurityIcon3 />
          <h6>Top-Notch Security</h6>
          <p>
            We offer Two-Factor Authentication (2FA) for crucial transactions,
            adding an extra layer of security .
          </p>
        </div>
        <div>
          <SecurityIcon4 />
          <h6>Regulatory Compliance</h6>
          <p>
            All savings are carefully managed by professionals with standard
            third-party custodian structure always in line with government
            regulations.
          </p>
        </div>
        <div>
          <SecurityIcon5 />
          <h6>Insuring your Money</h6>
          <p>
            We provide insurance coverage for all deposits in your Doyar account
            through the National Deposit Insurance Commission (NDIC)
            non-interest banking insurance scheme. In the event of a successful
            insurance claim on a deposit, the NDIC's Deposit Insurance Fund will
            offer compensation of up to 200,000 naira.
          </p>
        </div>
        <div>
          <SecurityIcon6 />
          <h6>Your Personal Information</h6>
          <p>
            We employ advanced encryption methods to safeguard your personal
            information, including sensitive account details and identity. We
            prioritize your privacy by not sharing your data with unauthorized
            parties, refraining from selling your information, and avoiding the
            use of your BVN to access other bank accounts you may hold.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Philosophy;
