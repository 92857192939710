import React from "react";
import { useStyles } from "./style";
import img from "../../../assets/about/avatars.webp";

const Change = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className="wrapper">
        <div class="left">
          <h6
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="1000"
          >
            At Doyar
          </h6>
          <h4
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="1200"
          >
            We're Changing Banking for Good!
          </h4>
          <p
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="1300"
          >
            Our passion lies in untangling the complexities of finance to solve
            people's problems rather than just selling products.{" "}
          </p>
        </div>
        <img
          src={img}
          alt="avatars"
          height={"463px"}
          data-aos="zoom-in"
          data-aos-easing="linear"
          data-aos-duration="1700"
        />
      </div>
    </div>
  );
};

export default Change;
