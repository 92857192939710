import React, { useEffect } from "react";
import Navbar from "../../components/navbar";
import Hero from "../../components/careers/hero";
import Benefits from "../../components/careers/benefits";
import Positions from "../../components/careers/positions";
import Pulse from "../../components/careers/pulse";
import Footer from "../../components/footer";
import JoinBetter from "../../components/general/join-better";

const Careers = () => {
  //add meta title and description
  useEffect(() => {
    document.title = "Careers | My Doyar";
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "Hello, we' re Doyar. The benefits of non-interest banking has been limited to a few. So we decided to share it with you. All on your phone."
      );
    document.body.classList.remove("disable-scroll");
  }, []);
  return (
    <>
      <Navbar />
      <Hero />
      <Benefits />
      <Positions />
      <Pulse />

      <JoinBetter />
      <Footer />
    </>
  );
};

export default Careers;
