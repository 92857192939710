import React from "react";
import { useStyles } from "./style";

const Content = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className="wrapper">
        <div className="header">
          <h3>Terms and Conditions</h3>
        </div>
        <div className="content">
          <h4>Background</h4>
          <p>
            These‌ ‌terms‌ ‌and‌ ‌conditions‌ ‌will‌ ‌establish‌ ‌a‌
            ‌foundation‌ ‌of‌ ‌your‌ ‌banking‌ ‌association‌ ‌with‌ ‌us,‌
            ‌Doyar.‌ ‌By‌ ‌opting‌ ‌to‌ ‌initiate‌ ‌an‌ ‌account‌ ‌with‌ ‌us,‌
            ‌you‌ ‌consent‌ ‌to‌ ‌the‌ ‌conditions‌ ‌and‌ ‌terms‌ ‌present‌
            ‌here.‌ ‌You‌ ‌also‌ ‌validate‌ ‌that‌ ‌you‌ ‌have‌ ‌furnished‌ ‌us‌
            ‌with‌ ‌the‌ ‌precise‌ ‌and‌ ‌entire‌ ‌details‌ ‌required‌ ‌to‌
            ‌construct‌ ‌your‌ ‌account‌ ‌and‌ ‌that‌ ‌you‌ ‌have‌ ‌provided‌
            ‌all‌ ‌paperwork,‌ ‌pictures,‌ ‌and‌ ‌particulars‌ ‌that‌ ‌empower‌
            ‌us‌ ‌to‌ ‌meet‌ ‌our‌ ‌regulatory‌ ‌commitments.‌ <br />
            If‌ ‌you‌ ‌do‌ ‌not‌ ‌endorse‌ ‌these‌ ‌conditions‌ ‌and‌ ‌terms,‌
            ‌kindly‌ ‌refrain‌ ‌from‌ ‌continuing‌ ‌and‌ ‌depart‌ ‌from‌ ‌the‌
            ‌application‌ ‌promptly.‌ ‌Furthermore,‌ ‌be‌ ‌advised‌ ‌that‌ ‌we‌
            ‌reserve‌ ‌the‌ ‌right‌ ‌to‌ ‌terminate‌ ‌your‌ ‌association‌ ‌with‌
            ‌us‌ ‌if‌ ‌we‌ ‌believe‌ ‌that‌ ‌you‌ ‌have‌ ‌breached‌ ‌any‌ ‌of‌
            ‌these‌ ‌conditions
          </p>
          <h4>The Objective Of Our Relationship With You</h4>
          <p>
            The aim of forming a connection with Doyar is to access exclusive
            services provided by Doyar for its clientele. Doyar will oversee a
            bank account for the client, along with delivering supplementary
            offerings such as banking and savings. You have the ability to
            control and handle your account through our Doyar app, and the
            necessary condition for initiating an account is a smartphone,
            equipped with an operational phone number, which adheres to our
            basic technological prerequisites for the operating system
            (iOS/Android) and is compatible with the most recent edition of our
            Doyar app.
          </p>
          <h4>When are these terms applicable?</h4>
          <p>
            These conditions become relevant when you opt to initiate an account
            with Doyar, and Doyar assigns you an account number. We reserve the
            right to alter the conditions and terms of our affiliation whenever
            necessary, though we won't enact these changes without prior
            notification. Any revisions will be clearly presented on our website
            and within our app. You will have the capability to retrieve the
            most recent edition of our terms whenever needed.
          </p>

          <h4>Opening an Account</h4>
          <p>
            You have the option to initiate an account with us provided that you
            are at least 18 years of age, a citizen of Nigeria, fulfill the
            aforementioned technological prerequisites, and haven't previously
            established an account with us. We consciously avoid interactions
            with individuals who do not fulfil these criteria. <br />
            Upon deciding to establish an account, you consent to us utilizing
            your details to execute and accept payments on your behalf via your
            Doyar account. If you wish for us to discontinue this practice, we
            would need to deactivate your account.
          </p>

          <h4>Verifying your identity</h4>
          <p>
            Financial regulations necessitate us to authenticate your identity
            prior to establishing an account on your behalf. At a minimum, an
            operational phone number, your profile image, and BVN are requisite.
            By providing additional details, such as valid identification
            papers, we can create an account unencumbered. <br />
            Aligned with regulatory protocols, we conduct thorough assessments
            on all accounts, making it your obligation to maintain accurate
            mailing, email, physical address, and other contact particulars
            within your profile
          </p>
          <h4>Language and Communication</h4>
          <p>
            English will serve as the principal language for our correspondence
            with you. While we might incorporate emojis or informal expressions,
            our communication will consistently be in English.
            <br />
            Should you possess any inquiries regarding your affiliation with
            Doyar, you can reach out to us through email utilizing the following
            contact information:
            <br />
            Email:{" "}
            <a href="mailto:investors@doyarbank.com">
              investors@doyarbank.com
            </a>{" "}
            or{" "}
            <a href="mailto:community@doyarbank.com">community@doyarbank.com</a>
          </p>
          <h4>Notifications</h4>
          <p>
            By initiating an account with us, you provide consent to receiving
            text (SMS), email, and push notifications from us. These
            notifications are intended exclusively for your awareness, and we
            will not be held accountable if you experience any detriment or harm
            due to unauthorized access to the conveyed information.
          </p>
          <p>
            Moreover, upon creating this account, you concur to deselect
            mandatory SMS alerts as directed by the CBN, in favor of push
            notifications. You consent to indemnify Doyar against all losses,
            damages, claims, demands, and expenditures of any kind that the Bank
            might incur, bear, or face, and against any legal actions,
            proceedings, or claims (including attorney's fees), whether civil or
            criminal, that might be lodged against Doyar due to such
            notifications.
          </p>
          <h4>Send/Receive Money</h4>
          <p>
            With Doyar, you'll have the capability to finance your Doyar account
            through bank transfers, cash deposits at designated partner banks.
            You'll have the capacity to effect immediate transfers.
          </p>
          <h4>Doyar Savings</h4>
          <p>
            Doyar savings facilitates effortless money-saving for you. This can
            be achieved by either deducting from your Doyar Bank account at a
            frequency you decide or through a single lump-sum savings. When the
            specified period concludes, the amassed funds will be moved to your
            Doyar Bank account. You can withdraw funds before the end of the
            stipulated period. For recurring savings, such withdrawals are free
            of charge, whereas for one-time lump sum savings, NO INTEREST fee
            will be incurred.
          </p>
          <h4>When things go Wrong</h4>
          <p>
            For the safeguarding of your funds, we require you to ensure the
            safety of your card, mobile device, and PIN at all times. It is your
            duty to uphold the security and confidentiality of your passwords,
            PINs, or any other identification means employed to access your bank
            account and our services. Employ a robust password and an exclusive
            code as your transaction PIN, restricting its use solely to your
            Doyar account. Do not grant access or utilization of your password
            or transaction PIN details to anyone. In the event of sharing these
            specifics, we cannot and shall not be held accountable for any loss
            or harm stemming from your lack of adherence.
          </p>
          <h4>Closing an Account</h4>
          <p>
            You hold the option to terminate your account at any point, and this
            will incur no charges. Should you opt for this course of action,
            kindly reach out to us. Nonetheless, you will remain responsible for
            any responsibilities linked to your account with us. Upon the
            account's closure, you will lose access to all Doyar services.{" "}
            <br />
            We maintain the prerogative to shut down, pause, freeze, or restrict
            access to your account, under the following circumstances:
          </p>
          <ul>
            <li>
              The information we collect from you does not align with regulatory
              prerequisites.
            </li>
            <li>
              You fail to meet, or you violate, the terms and conditions
              detailed herein.
            </li>
            <li>
              Your actions pose risks or potential legal complications for us.
            </li>
            <li>We are mandated to do so by legal obligations.</li>
            <li>
              There is a report of, or our investigations unveil your
              involvement in, fraudulent or suspicious actions associated with
              your Doyar account.
            </li>
          </ul>
          <p style={{ marginTop: "16px" }}>
            As a financial institution, it's imperative for us to adhere to the
            global sanction laws and regulations set forth by the Office of
            Foreign Assets Control (OFAC) in the United States of America, the
            European Union (EU), the United Nations (UN), the Treasury of the
            United Kingdom (HMT), and also to abide by local laws and
            regulations that apply to issuing banks, including those stipulated
            by the CBN. Therefore, we maintain the prerogative to conclude your
            account without assuming any responsibility. Additionally, we aren't
            obligated to initiate this account or process any payments if you
            breach any sanctions from OFAC, EU, UN, and HTM, or if any person
            (whether individual, corporate, or governmental) listed in the OFAC,
            EU, UN, HMT, or local sanctions registers is involved, or if there's
            any association with Cuba, Sudan, North Korea, Iran, Myanmar, or any
            other countries occasionally added to the sanctions list, or their
            governmental entities. Furthermore, Doyar is mandated to abide by
            all existing laws in Nigeria, encompassing the regulations,
            directives, guidelines, circulars, and correspondences established
            by the CBN and other regulatory bodies at different times. In
            harmony with the applicable regulations, Doyar does not engage in
            cryptocurrency transactions and refrains from facilitating payments
            related to cryptocurrency dealings and associated transactions.
            Thus, you validate that you won't employ your Doyar account to
            engage in, trade, transact, or accept any payments related to
            virtual currencies.
            <br />
            Moreover, you confirm that your account will be exclusively employed
            for legitimate intents and will not be utilized for illicit or
            criminal undertakings, which encompass, but are not restricted to,
            acquiring funds through deceitful means or any other deceitful
            actions that contravene the prevailing laws in Nigeria.
          </p>
          <h4>Data Protection</h4>
          <p>
            By initiating this account, you grant us authorization to handle
            your personal data:
          </p>
          <ul>
            <li>
              for the provision of products and services according to this
              agreement, as well as any additional products and services you
              might seek;
            </li>
            <li>
              for the purpose of conducting statistical and various analyses,
              aiming to identify potential markets and trends, and to assess and
              enhance our business (which incorporates the enhancement of
              existing and creation of new products and services);
            </li>
            <li>
              in nations beyond Nigeria where the products or services are
              utilized, and in cases where these countries lack equivalent data
              protection regulations to those applicable in Nigeria, we will
              make efforts, when feasible, to secure agreement from the
              recipient party regarding our privacy policies.
            </li>
          </ul>
          <h4>Marketing and Promotion</h4>
          <p>
            We might also employ your personal or alternative details to
            communicate information about new products and services that could
            pique your interest. By initiating an account with us, you grant us
            permission to forward you notifications regarding the Doyar product.
            You have the option to opt out of our newsletter by utilizing the
            provided link for that specific purpose in every newsletter we
            transmit.
          </p>
          <h4>
            Bank Verification Number (BVN) Operations and Watch-List for the
            Nigerian Banking Industry
          </h4>
          <ul>
            <li>
              You agree to our implementation of limitations on your account and
              notifying law enforcement entities, such as the Economic and
              Financial Crimes Commission, the Nigerian Financial Intelligence
              Unit, the Nigerian Police Force, etc., if any fraudulent conduct
              is linked to the functioning of your account. You also agree to
              our reporting to Nigeria Inter-Bank Settlement Systems Plc (NIBBS)
              to update the Watchlist Database of the Nigerian Banking Industry
              and the CBN in the event of any fraudulent activities being
              associated with the operation of your account.
            </li>
          </ul>
          <h4>Declaration</h4>
          <p>
            By opting to continue, you acknowledge your comprehension that you
            are establishing an account with Doyar Bank and that all the details
            you have furnished are intended for this specific objective. You
            commit to adhering to the aforementioned terms and conditions, which
            oversee our activities. You also validate the accuracy and
            truthfulness of the information provided. Upon initiating an account
            with us, you provide consent for us to conduct routine identity and
            fraud prevention verifications. In conclusion, you pledge to
            reimburse the bank for any losses incurred due to incorrect
            information or inaccuracies in the particulars you have provided to
            us
          </p>
        </div>
      </div>
    </div>
  );
};

export default Content;
