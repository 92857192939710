import React, { useEffect } from "react";
import Navbar from "../../components/navbar";
import Hero from "../../components/security/hero";
import Footer from "../../components/footer";
import JoinBetter from "../../components/general/join-better";
import Philosophy from "../../components/security/philosophy";
import Report from "../../components/security/report";

const Send = () => {
  //add meta title and description
  useEffect(() => {
    document.title = "Security | My Doyar";
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "Hello, we' re Doyar. The benefits of non-interest banking has been limited to a few. So we decided to share it with you. All on your phone."
      );
    document.body.classList.remove("disable-scroll");
  }, []);
  return (
    <>
      <Navbar />
      <Hero />
      <Philosophy />
      <Report />
      <JoinBetter />
      <Footer />
    </>
  );
};

export default Send;
