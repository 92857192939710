import { makeStyles } from "@mui/styles";
import { colors } from "../../../styles/colors";
import bg from "../../../assets/about/man-suite.jpg";

export const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: "100px",
    "& .wrapper": {
      display: "flex",
      flexDirection: "column",
      gap: "70px",
      "& .who": {
        display: "flex",
        justifyContent: "space-between",
        gap: "53px",
        alignItems: "center",
        [theme.breakpoints.down("md")]: {
          flexDirection: "column",
        },
        "& .who__left": {
          position: "relative",
          width: "100%",
          "&.mobile": {
            display: "none",
            [theme.breakpoints.down("md")]: {
              display: "block",
            },
          },
          "&.desktop": {
            display: "block",
            [theme.breakpoints.down("md")]: {
              display: "none",
            },
          },
          "& .who__left__img_container": {
            overflow: "hidden",
            position: "absolute",
            height: "499px",
            width: "464px",
            top: "-32px",
            left: "0",
            borderRadius: "20px",
            [theme.breakpoints.down("md")]: {
              width: "90%",
              right: "5%",
            },

            "& .who__left__img": {
              width: "100%",
              height: "100%",
              backgroundColor: "#fff",
              backgroundImage: `url(${bg})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
              "&:hover,:focus": {
                transition: "all .4s ease-in-out",
                transform: "scale(1.1)",
              },
            },
          },
          "& .who__left__shadow": {
            background: "#F4F6F9",
            borderRadius: "20px",
            height: "499px",
            width: "464px",
            marginLeft: "32px",
            // marginTop: "-32px",
            [theme.breakpoints.down("md")]: {
              width: "90%",
              marginLeft: "5%",
            },
          },
        },
        "& .who__right": {
          "& h6": {
            fontFamily: "'Montserrat', sans-serif",
            fontSize: "36px",
            fontWeight: "700",
            lineHeight: "64px",
            color: "#181817",
            marginBottom: "22px",
            [theme.breakpoints.down("md")]: {
              fontSize: "30px",
              lineHeight: "35px",
              textAlign: "center",
            },
          },
          "& p": {
            fontFamily: "'Montserrat', sans-serif",
            fontSize: "18px",
            fontWeight: "400",
            lineHeight: "34px",
            color: colors.doyarAsh,
            marginBottom: "22px",
            [theme.breakpoints.down("md")]: {
              textAlign: "center",
            },
          },
        },
      },
      "& .how": {
        padding: "58px 41px",
        background: "#F4F6F9",
        display: "grid",
        gridTemplateColumns: "1.1fr 1fr 1fr",
        gap: "62px",
        borderRadius: "20px",
        [theme.breakpoints.down("md")]: {
          gridTemplateColumns: "1fr",
          padding: "58px 20px",
        },

        "& h4": {
          fontFamily: "'Montserrat', sans-serif",
          fontSize: "36px",
          fontWeight: "700",
          lineHeight: "50px",
          color: "#181817",
          marginBottom: "26px",
          [theme.breakpoints.down("md")]: {
            fontSize: "30px",
            lineHeight: "35px",
            textAlign: "center",
          },
        },
        "& h6": {
          fontFamily: "'Montserrat', sans-serif",
          fontSize: "18px",
          fontWeight: "600",
          lineHeight: "22px",
          color: colors.doyarDark,
          [theme.breakpoints.down("md")]: {
            fontSize: "16px",
            lineHeight: "22px",
          },
        },
        "& p": {
          fontFamily: "'Montserrat', sans-serif",
          fontSize: "18px",
          fontWeight: "400",
          lineHeight: "30px",
          color: colors.doyarDark,
          "&.intro": {
            "&.mobile": {
              display: "none",
              [theme.breakpoints.down("md")]: {
                display: "block",
              },
            },
            "&.desktop": {
              display: "block",
              [theme.breakpoints.down("md")]: {
                display: "none",
              },
            },
          },
          [theme.breakpoints.down("md")]: {
            "&.intro": {
              textAlign: "center",
            },
          },
        },
        "& .how__card": {
          background: "#fff",
          borderRadius: "20px",
          padding: "20px",
          "&.mobile": {
            display: "none",
            [theme.breakpoints.down("md")]: {
              display: "block",
            },
          },
          "&.desktop": {
            display: "block",
            [theme.breakpoints.down("md")]: {
              display: "none",
            },
          },

          "& .row": {
            display: "flex",
            alignItems: "center",
            gap: "13px",
            marginBottom: "26px",
          },
        },
      },
    },
  },
}));
