import React from "react";
import { useStyles } from "./style";
import { MissionIcon, VisionIcon } from "../../../assets/icons";

const Who = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className="wrapper">
        <div className="who">
          <div
            className="who__left desktop"
            data-aos="fade-right"
            data-aos-easing="linear"
            data-aos-duration="1400"
          >
            <div className="who__left__img_container ">
              <div className="who__left__img"></div>
            </div>
            <div className="who__left__shadow"></div>
          </div>
          <div className="who__left mobile">
            <div className="who__left__img_container ">
              <div className="who__left__img"></div>
            </div>
            <div className="who__left__shadow"></div>
          </div>
          <div className="who__right">
            <h6
              className="who__left"
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="1400"
            >
              Who We Are
            </h6>
            <p
              data-aos="fade-up"
              data-aos-easing="linear"
              data-aos-duration="1500"
            >
              We are a financial establishment own by some experienced ex
              bankers, employees benefit trust and a group of amazing angel
              investors.
            </p>
            <p
              data-aos="fade-up"
              data-aos-easing="linear"
              data-aos-duration="1700"
            >
              Our foundation is built on the principles of interest-free
              banking. We focus on fostering an environment of trust and
              integrity, benefiting both our customers and the community.
            </p>
            <p
              data-aos="fade-up"
              data-aos-easing="linear"
              data-aos-duration="1900"
            >
              By embracing ethical practices, we ensure your money is used for
              socially responsible investments, supporting projects that promote
              sustainable development and the welfare of society.
            </p>
          </div>
        </div>
        <div className="how">
          <div>
            <h4
              data-aos="fade-down-right"
              data-aos-easing="linear"
              data-aos-duration="1400"
            >
              How we Intend to Change Banking for all of Us!
            </h4>
            <p
              className="intro desktop"
              data-aos="fade-down-left"
              data-aos-easing="linear"
              data-aos-duration="1500"
            >
              By bringing non-interest banking and other Islamic banking tools
              for the benefit of all.
            </p>
            <p
              className="intro mobile"
              data-aos="fade-in"
              data-aos-easing="linear"
              data-aos-duration="1500"
            >
              By bringing non-interest banking and other Islamic banking tools
              for the benefit of all.
            </p>
          </div>
          <div
            className="how__card desktop"
            data-aos="fade-down-left"
            data-aos-easing="linear"
            data-aos-duration="1600"
          >
            <div className="row">
              <VisionIcon /> <h6>Our Vision</h6>
            </div>
            “For all of us”. Bringing the benefits of non-interest banking to
            everyone.
          </div>
          <div
            className="how__card mobile"
            data-aos="fade-in"
            data-aos-easing="linear"
            data-aos-duration="1600"
          >
            <div className="row">
              <VisionIcon /> <h6>Our Vision</h6>
            </div>
            “For all of us”. Bringing the benefits of non-interest banking to
            everyone.
          </div>
          <div
            className="how__card desktop"
            data-aos="fade-down-left"
            data-aos-easing="linear"
            data-aos-duration="1700"
          >
            <div className="row">
              <MissionIcon /> <h6>Our Mission</h6>
            </div>
            To bring the opportunities of non-interest banking to everyone. In
            an easy, exciting and secured way. While leveraging technology.
          </div>
          <div
            className="how__card mobile"
            data-aos="fade-in"
            data-aos-easing="linear"
            data-aos-duration="1700"
          >
            <div className="row">
              <MissionIcon /> <h6>Our Mission</h6>
            </div>
            To bring the opportunities of non-interest banking to everyone. In
            an easy, exciting and secured way. While leveraging technology.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Who;
