import { makeStyles } from "@mui/styles";
import { colors } from "../../../styles/colors";

export const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "104px",
    display: "flex",
    flexDirection: "column",
    gap: "80px",
    "& .grid": {
      display: "grid",
      gridTemplateColumns: "repeat(2, 1fr)",
      gap: "110px",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "40px",
      },
      "& .text": {
        maxWidth: "640px",
        display: "flex",
        flexDirection: "column",
        gap: "40px",
        [theme.breakpoints.down("md")]: {
          gap: "20px",
          maxWidth: "100%",

          alignItems: "center",
        },
        "& h6": {
          fontFamily: "'Montserrat', sans-serif",
          fontSize: "36px",
          lineHeight: "45px",
          fontWeight: "700",
          color: "#22222",
          [theme.breakpoints.down("md")]: {
            fontSize: "30px",
            lineHeight: "37px",
            textAlign: "center",
          },
        },
        "& p": {
          fontFamily: "'Montserrat', sans-serif",
          fontSize: "16px",
          lineHeight: "24px",
          fontWeight: "500",
          color: colors.doyarAsh,
          marginBottom: "20px",
          [theme.breakpoints.down("md")]: {
            textAlign: "center",
          },

          "& span": {
            fontWeight: "400 !important",
            color: colors.doyarPeach + " !important",
            fontStyle: "italic",
          },
        },
      },
      "& img": {
        height: "341px",
        [theme.breakpoints.down("md")]: {
          height: "auto",
          width: "100%",
        },
      },
    },
  },
}));
