import React, { useState } from "react";
import { useStyles } from "./style";
import logo from "../../../assets/images/logo.webp";
import { IconOpen, SearchIcon } from "../../../assets/icons";

const FaqContent = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const handleOpen = (id) => {
    id === open ? setOpen(false) : setOpen(id);
  };

  const faqs = [
    {
      id: 1,
      header: "General",
      items: [
        {
          id: 1,
          question: "Is Doyar a registered bank?",
          answer:
            "Yes, we have a microfinance banking license from the Central Bank of Nigeria. Our registration number is RC Number 7650129",
        },
        {
          id: 2,
          question: "How is Doyar different from other banks?",
          answer:
            "We're mobile-only, non-interest bank that offers higher returns on your savings and payments.",
        },
        {
          id: 3,
          question: "What is Non-Interest Banking (NIB)?",
          answer:
            "Non-Interest banking operates on defining principles such as: interest prohibition in debt and exchange contracts; the prohibition of uncertainty or speculative behavior in business transactions; the prohibition of any form of gambling. It also prohibits funding of unethical concerns such as, alcohol, tobacco, ammunition manufacturing and adult entertainment institutions; just to mention a few. Non-Interest Banks engage in partnership contracts, trading contracts, leasing contracts, and other financial services that conform to Islamic commercial jurisprudence.",
        },
      ],
    },
    {
      id: 2,
      header: "Savings & Investments",
      items: [
        {
          id: 1,
          question: "What saving options do you offer?",
          answer:
            "<strong>DoyarSave</strong><br/>This savings plan offers you the flexibility to save daily, weekly or monthly towards your set goal. At the end date of your savings goal which you'll receive 60% of the profits, following the <a href='https://islamic-banking.com/mudarabah' target='_blank'>Mudarabah</a> profit-sharing principle.<br/><br/><strong>DoyarFixed</strong><br/>This savings plan offers a fixed-term approach to reach your financial goals, allowing you to save a fixed amount for a period of one month to two years. Your savings remain locked until maturity, at which point you'll receive 60% of the profits, following the <a href='https://islamic-banking.com/mudarabah' target='_blank'>Mudarabah</a> profit-sharing principle",
        },
        {
          id: 2,
          question: "What does Doyar invest my money in?",
          answer:
            "We invest your money ethically in line with the non-interest principle, under a strict guidance of our Advisory Council of Experts. eg <a href='https://www.treasurers.org/hub/treasurer-magazine/what-are-sukuk-and-how-do-they-work'>Sukuk funds</a>",
        },
        {
          id: 3,
          question: "Do you give loans?",
          answer:
            "We don't give loans, but very soon we will be offering asset backed non interest products",
        },
      ],
    },
    {
      id: 3,
      header: "Fees & charges",
      items: [
        {
          id: 1,
          question: "What fees do you charge?",
          answer:
            "You will be charged 10 naira for transfers less than 5,000, 25 naira above 10,000. With DOYARFIXED AND DOYARSAVE the profit-sharing ration is 60% to the customer 40% to the Bank.",
        },
        {
          id: 2,
          question: "How do you make money?",
          answer:
            "We make money from our share of the profits we make from managing your savings. We also make money from commission on transfers.",
        },
      ],
    },
    {
      id: 4,
      header: "Onboarding",
      items: [
        {
          id: 1,
          question: "What do I need to open a Doyar account?",
          answer:
            "To open a Doyar account, you should have a BVN, NIN, phone number an email address and a verifiable address.",
        },
        {
          id: 2,
          question: "Types of Account",
          answer:
            "<strong>Tier 1</strong><br/>Daily transactions of N50,000<br/>Send and Receive<br/>Total Balance: N300,000.<br/><br/><strong>Tier 2</strong><br/>Daily transactions of N200,000 <br/>Send and Receive.<br/>Total of N500,000.<br/><br/><strong>Tier 3</strong><br/>Daily transaction of  N1,000,000<br/>Send and Receive<br/>Total Balance of N1,000,000,000.<br/> <br/><br/><strong style='color:#ED6669'>KYC FOR EACH TIER</strong><br/><strong>Tier 1 </strong><br/>BVN, NIN, PHONE NUMBER.<br/><strong>Tier 2</strong> <br/>NIN, Valid ID (National id, driving license, international passport and Voters Card. <br/><strong>Tier 3</strong><br/>Valid ID and a proof of address. A proof of address can be any utility, your university admission letter, rent agreement, bank, employee letter, insurance agreement, or bank statement (minimum of one month).",
        },
        {
          id: 3,
          question: "How will I add money to my Doyar Account?",
          answer:
            "There are several ways to add money to your Doyar account:<br/> You can transfer money to your Doyar account from any Nigerian bank with a bank app or through internet banking or paystack.<br/>You can also add money to your upgraded account with a Nigerian debit card. Tap Add Money on your Doyar dashboard and choose the Add Card option.<br/>If you have cash, you can deposit it into your Doyar account with any POS agent.<br/>",
        },
        {
          id: 4,
          question: "Is there a minimum balance?",
          answer: "No, we don’t have a minimum account balance.",
        },
      ],
    },
    {
      id: 5,
      header: "Privacy & Security",
      items: [
        {
          id: 1,
          question: "Why do you need my BVN?",
          answer:
            "We ask you to confirm your BVN so we can be sure no one is pretending to be you. When you confirm your BVN, we match your details with the information on the national BVN database owned by the Central Bank. We won’t use your BVN to access your other bank account(s).",
        },
        {
          id: 2,
          question:
            "Since you don’t have branches, how will I get in touch with you?",
          answer: "All our contact channels are up 24/7.",
        },
        {
          id: 3,
          question: "What are the risk of banking with Doyar?",
          answer:
            "Investments are monitored on a daily basis by Doyar to ensure that they will provide the expected profit rate which was quoted to the customer. <br/>As the return is “expected profit”, rather than “guaranteed interest”, there is a small element of risk, which is shared between the customer and the Bank. However, Doyar will always work towards archiving at least the profit rate quoted. Additionally, Doyar savers are covered by the <a href='https://ndic.gov.ng/'>NDIC (Nigeria Deposit Insurance Corporation)</a> Non-interest Compensation Scheme.",
        },
        {
          id: 4,
          question: "Is Doyar bank transparent?",
          answer:
            "Unlike many conventional banks, Doyar is completely transparent about where customers’ deposits are invested, and, just as importantly, where they are never invested.<br/>Deposits are invested in asset backed, relatively secure assets. They are never invested in any activity that is not in keeping with the values of non-interest banking as stipulated by the Central Bank of Nigeria – such as any activity connected to gambling, alcohol, pornography, arms, tobacco or any interest-bearing activity – or any speculative activity. This includes derivative and conventional instruments, such as options, warrants, swaps, futures, auction rate securities, and currency options, as well as structured notes on assets.",
        },
      ],
    },
    {
      id: 6,
      header: "Help & Support",
      items: [
        {
          id: 1,
          question: "Will I get account statements?",
          answer:
            "Yes, you can request an account statement for a specific time period. To request an account statement, tap Account on the app then tap Statements.",
        },
        {
          id: 2,
          question: "Where's your office?",
          answer: "We're at Km 2, Tudun Yola-Kasakali Road, Kofar Waika Kano",
        },
        {
          id: 3,
          question: "What should I do if I need help?",
          answer:
            "The easiest way to reach us is to tap the chat icon on the Doyar app then tap Chat  with Us.<br/>You can also send an email to support@doyarbank.com or call …………………….. between 8:00 am and 5:00 pm on weekdays and weekends.",
        },
      ],
    },
  ];

  const filteredFaqs = faqs.map((faq) => ({
    ...faq,
    items: faq.items.filter(
      (item) =>
        item.question.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.answer.toLowerCase().includes(searchQuery.toLowerCase())
    ),
  }));

  return (
    <div className={classes.container}>
      <div className="wrapper">
        <div className="hero">
          <img src={logo} height={"43px"} alt="Doyar logo" />
          <p>Frequently Asked Questions</p>
        </div>
        <h5>What are you looking to know about?</h5>
        <div className="searchContainer">
          <SearchIcon />
          <input
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <p className="history">
          People often search for: <span>Personal banking, savings, </span>
        </p>
        {filteredFaqs.map(
          (faq) =>
            faq.items.length > 0 && (
              <div className="columns" key={faq.id}>
                <h6>{faq.header}</h6>
                <div>
                  {faq.items.map((item) => (
                    <div className="faqs" key={item.id}>
                      <div
                        className={
                          open === faq?.id + "-" + item.id
                            ? "faq opened"
                            : "faq"
                        }
                        onClick={() => handleOpen(faq?.id + "-" + item.id)}
                      >
                        <div className="question">
                          <h3>{item.question}</h3>
                          <IconOpen className={"icon"} />
                        </div>
                        <div className="answer">
                          <p
                            dangerouslySetInnerHTML={{ __html: item.answer }}
                          ></p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )
        )}
        <div style={{ textAlign: "center", margin: "20px 0px" }}>
          <h1>Still have more questions?</h1>
          <p className="info">
            Send an email to{" "}
            <a className="red" href="mailto:support@doyarbank.com">
              support@doyarbank.com
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default FaqContent;
