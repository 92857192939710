import { makeStyles } from "@mui/styles";
import { colors } from "../../../styles/colors";

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: "97px 0px",
    background: "#F4F6F9",
    fontFamily: "'Montserrat', sans-serif",

    "& .wrapper": {
      display: "flex",
      // justifyContent: "space-between",
      gap: "60px",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        justifyContent: "center",
      },
      "& .text": {
        maxWidth: "600px",
        [theme.breakpoints.down("md")]: {
          maxWidth: "100%",
          "& img": {
            display: "none",
          },
        },
        "& h1": {
          color: "#181817",
          fontSize: "36px",
          fontWeight: 700,
          lineHeight: "50px",
          marginBottom: "16px",
          maxWidth: "380px",
          [theme.breakpoints.down("md")]: {
            textAlign: "center",
            maxWidth: "100%",
            fontSize: "28px",
            lineHeight: "35px",
          },
        },
        "& p": {
          color: colors.doyarDark,
          fontSize: "18px",
          fontWeight: 500,
          lineHeight: "28px",
          [theme.breakpoints.down("md")]: {
            textAlign: "center",
            fontSize: "16px",
          },
        },
      },
      "& form": {
        display: "flex",
        flexDirection: "column",
        gap: "30px",
        width: "100%",
        "& .form-group": {
          display: "flex",
          flexDirection: "column",
          gap: "12px",
          width: "100%",

          "& label": {
            color: "#344054",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "20px",
          },
          "& input,textarea": {
            width: "100%",
            padding: "14px 15px",
            borderRadius: "8px",
            border: "1px solid #a6a7a9",
            color: colors.doyarDark,
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "17px",
            resize: "none",
            "&:placeholder": {
              color: "#899792",
            },
          },
        },
        "& .form-row": {
          display: "flex",
          gap: "26px",
        },
        "& button": {
          width: "100%",
          padding: "19px 19px",
          borderRadius: "8px",
          border: "none",
          background: colors.doyarDark,
          color: colors.white,
          fontFamily: "'Montserrat', sans-serif",
          fontSize: "18px",
          fontWeight: "500",
          cursor: "pointer",
          textAlign: "center",
          marginTop: "16px",
        },
      },
      "& img.desktop": {
        display: "none",
        [theme.breakpoints.down("md")]: {
          marginTop: "-40px",
          display: "block",
          width: "100%",
          height: "auto",
        },
      },
    },
  },
}));
