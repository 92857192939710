import { makeStyles } from "@mui/styles";
import { colors } from "../../../styles/colors";

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: "112px 0",
    fontFamily: "'Montserrat', sans-serif",
    "&.wrapper": {
      display: "grid",
      gridTemplateColumns: "repeat(2,1fr)",
      gap: "86px",
      [theme.breakpoints.down("md")]: {
        gridTemplateColumns: "1fr",
        gap: "34px",
        padding: "50px 0",
        "& img": {
          height: "auto",
          width: "100%",
        },
      },

      "& h1": {
        color: "#181817",
        fontSize: "36px",
        fontWeight: 700,
        lineHeight: "50px",
        marginBottom: "16px",
        [theme.breakpoints.down("md")]: {
          textAlign: "center",
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: "30px",
        },
      },

      "& p": {
        color: "#30415A",
        fontSize: "18px",
        fontWeight: 400,
        lineHeight: "34px",
        marginBottom: "54px",
        "&.intro": {
          display: "none",
        },
        [theme.breakpoints.down("md")]: {
          textAlign: "center",
        },
        [theme.breakpoints.down("sm")]: {
          "&.intro": {
            display: "block",
          },
        },
      },
      "& a": {
        color: colors.doyarPeach,
      },
    },
  },
}));
