import { makeStyles } from "@mui/styles";
import bg from "../../../assets/privacy/hero-img.webp";

export const useStyles = makeStyles((theme) => ({
  container: {
    "&.wrapper": {
      marginTop: "160px",
      backgroundImage: `url(${bg})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      height: "258px",
      borderRadius: "20px",
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
  },
}));
