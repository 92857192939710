import { makeStyles } from "@mui/styles";
import { colors } from "../../../styles/colors";

export const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "112px",
    fontFamily: "'Montserrat', sans-serif",
    "&.wrapper": {
      "& .header": {
        textAlign: "center",
        marginBottom: "60px",
      },
      "& h4": {
        color: colors.doyarPeach,
        fontSize: "18px",
        fontWeight: 600,
        lineHeight: "22px",
        [theme.breakpoints.down("md")]: {
          textAlign: "center",
        },
      },
      "& h1": {
        color: "#181817",
        fontSize: "36px",
        fontWeight: 700,
        lineHeight: "50px",
        marginBottom: "16px",
        [theme.breakpoints.down("md")]: {
          textAlign: "center",
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: "30px",
        },
      },
      "& h6": {
        margin: "16px 0 ",
        color: "#181817",
        fontSize: "18px",
        fontWeight: 600,
        lineHeight: "22px",
        [theme.breakpoints.down("md")]: {
          textAlign: "center",
        },
      },
      "& p": {
        color: "#30415A",
        fontSize: "18px",
        fontWeight: 400,
        lineHeight: "34px",
        marginBottom: "54px",
        "&.intro": {
          display: "none",
        },
        [theme.breakpoints.down("md")]: {
          textAlign: "center",
        },
        [theme.breakpoints.down("sm")]: {
          "&.intro": {
            display: "block",
          },
        },
      },
      "& .cards": {
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        gap: "74px",
        [theme.breakpoints.down("sm")]: {
          gridTemplateColumns: "1fr",
          gap: "26px",
          "& div": {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          },
          "& svg": {
            height: "64px",
            width: "64px",
          },
        },
      },
    },
  },
}));
