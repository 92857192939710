import React, { useEffect } from "react";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import FaqContent from "../../components/faqs/content";

const Faqs = () => {
  //add meta title and description
  useEffect(() => {
    document.title = "FAQ | My Doyar";
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "Hello, we' re Doyar. The benefits of non-interest banking has been limited to a few. So we decided to share it with you. All on your phone."
      );
    document.body.classList.remove("disable-scroll");
  }, []);
  return (
    <>
      <Navbar />
      <FaqContent />

      <Footer />
    </>
  );
};

export default Faqs;
