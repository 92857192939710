import { makeStyles } from "@mui/styles";
import { colors } from "../../../styles/colors";

export const useStyles = makeStyles((theme) => ({
  container: {
    background: colors.white,
    padding: "76px 0px",
    "& .wrapper": {
      "& .header": {
        "& h3": {
          fontSize: "44px",
          fontWeight: "bold",
          lineHeight: "50px",
          fontFamily: "'Montserrat', sans-serif",
          color: "#181817",
          marginBottom: "16px",
          textAlign: "center",
          [theme.breakpoints.down("md")]: {
            fontSize: "32px",
            lineHeight: "48px",
          },
          [theme.breakpoints.down("sm")]: {
            fontSize: "24px",
            lineHeight: "32px",
          },
        },
      },
      "& .content": {
        marginTop: "16px",
        "& h4": {
          fontSize: "24px",
          lineHeight: "30px",
          color: "#181817",
          fontFamily: "'Inter', sans-serif",
          margin: "16px 0px",
          [theme.breakpoints.down("sm")]: {
            fontSize: "18px",
            lineHeight: "24px",
          },
        },

        "& p, ul, li ": {
          fontSize: "18px",
          lineHeight: "30px",
          color: colors.secondary,
          fontFamily: "'Inter', sans-serif",
          textAlign: "justify",
          [theme.breakpoints.down("sm")]: {
            fontSize: "16px",
            lineHeight: "24px",
          },
          "& a": {
            color: colors.doyarPeach,
          },
        },
      },
    },
  },
}));
