import { makeStyles } from "@mui/styles";
import { colors } from "../../../styles/colors";
import bg from "../../../assets/careers/hero-bg.svg";

export const useStyles = makeStyles((theme) => ({
  container: {
    // paddingBottom: "97px",
    background: `url(${bg}) no-repeat center`,
    backgroundSize: "cover",
    [theme.breakpoints.down("md")]: {
      background: "#F4F6F9",
    },
    "& .wrapper": {
      fontFamily: "'Montserrat', sans-serif",
      display: "flex",
      alignItems: "center",
      paddingTop: "130px",
      "& img": {
        "&.mobile": {
          display: "none",
          [theme.breakpoints.down("md")]: {
            display: "block",
          },
        },
        "&.desktop": {
          display: "block",
          [theme.breakpoints.down("md")]: {
            display: "none",
          },
        },
      },
      [theme.breakpoints.down("md")]: {
        paddingTop: "80px",
        flexDirection: "column",
        justifyContent: "center",
        gap: "40px",

        "& img": {
          width: "100%",
          height: "auto",
        },
      },

      "& h6": {
        color: colors.doyarPeach,
        fontSize: "18px",
        fontWeight: 600,
        lineHeight: "22px",
        [theme.breakpoints.down("md")]: {
          textAlign: "center",
        },
      },
      "& h1": {
        color: "#181817",
        fontSize: "36px",
        fontWeight: 700,
        lineHeight: "50px",
        marginBottom: "16px",
        [theme.breakpoints.down("md")]: {
          textAlign: "center",
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: "24px",
        },
      },
      "& p": {
        color: "#30415A",
        fontSize: "18px",
        fontWeight: 400,
        lineHeight: "34px",
        marginBottom: "54px",
        [theme.breakpoints.down("md")]: {
          textAlign: "center",
          fontSize: "16px",
        },
      },
    },
  },
}));
