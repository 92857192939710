import React from "react";
import { useStyles } from "./style";
import { ContactIcon4 } from "../../../assets/icons";

const Danger = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className="wrapper">
        <div className="card">
          <div className="card_img"></div>
        </div>
        <div className="warning">
          <ContactIcon4 />
          <p>
            Doyar Staff will <span>NEVER</span> ask for sensitive information,
            including passcodes, ATM card details or any private keys.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Danger;
