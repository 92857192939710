import React from "react";
import { useStyles } from "./footer.style";
import { Link } from "react-router-dom";
import barcode from "../../assets/images/barcode.webp";
import {
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon,
  TwitterIcon,
} from "../../assets/icons";

const Footer = () => {
  var today = new Date();
  var year = today.getFullYear();

  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className="wrapper">
        <div className={classes.content}>
          <div>
            <h6>Personal</h6>
            <ul>
              <li>
                <a href="/send-and-receive-money">Send & Receive Money</a>
              </li>
              <li>
                <a href="/savings">Savings</a>
              </li>
            </ul>
          </div>
          <div>
            <h6>Company</h6>
            <ul>
              <li>
                <a href="/about">About Us</a>
              </li>
              <li>
                <a href="/careers">Careers</a>
              </li>
              <li>
                <a href="/community">Community</a>
              </li>
            </ul>
          </div>
          <div>
            <h6>Help</h6>
            <ul>
              <li>
                <a href="/contact">Get in Touch</a>
              </li>
              <li>
                <a href="/faqs">FAQ</a>
              </li>
              <li>
                <a href="mailto:support@doyarbank.com">support@doyarbank.com</a>
              </li>
            </ul>
          </div>
          <div>
            <h6>Legal</h6>
            <ul>
              <li>
                <a href="/terms-and-conditions">Terms and Conditions</a>
              </li>
              <li>
                <a href="/privacy-policy">Privacy Policy</a>
              </li>
              <li>
                <a href="/security">Security</a>
              </li>
            </ul>
          </div>
          <img src={barcode} alt="barcode" height={162} />
        </div>
        <p className="info">
          We are currently waiting for regulatory approval for our license. We
          will ensure that all deposit are insured in line with the Central Bank
          of Nigeria regulations on non-interest banking.
        </p>
      </div>
      <div className={classes.copyright}>
        <div className="wrapper">
          <span>{year}. All Rights Reserved</span>
          <span className="company">Doyar</span>
          <div className="socials">
            <a
              href="https://www.linkedin.com/company/my-doyar"
              target="_blank"
              rel="noreferrer"
            >
              <LinkedInIcon />
            </a>
            <a
              href="https://www.facebook.com/mydoyar"
              target="_blank"
              rel="noreferrer"
            >
              <FacebookIcon />
            </a>
            <a
              href="https://www.instagram.com/my_doyar"
              target="_blank"
              rel="noreferrer"
            >
              <InstagramIcon />
            </a>
            <a
              href="https://www.x.com/mydoyar"
              target="_blank"
              rel="noreferrer"
            >
              <TwitterIcon />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
