import { makeStyles } from "@mui/styles";
import { colors } from "../../../styles/colors";

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: "100px 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      "& img": {
        width: "100%",
        height: "auto",
      },
    },
    "& .here": {
      display: "grid",
      gridTemplateColumns: "1fr 1.1fr",
      gap: "16px",
      [theme.breakpoints.down("sm")]: {
        gap: "4px",
      },
    },
    "& h4": {
      fontFamily: "'Montserrat', sans-serif",
      fontSize: "54px",
      fontWeight: "600",
      lineHeight: "50px",
      color: "#181817",
      marginBottom: "60px",
      "& span": {
        display: "none", // Hide the span by default
        color: colors.doyarPeach,
        opacity: 0.3,
        transform: "translateY(30%)", // Move the element below the container
        animation: "$fadeInOut .5s ease-in-out ",
        "&.active": {
          display: "inline-block", // Show the span when it has the "active" class
          opacity: 1,
          transform: "translateY(0)", // Move the element back to its original position
        },
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "30px",
        lineHeight: "35px",
        textAlign: "center",
      },
      "&.texts": {
        textAlign: "start !important",
      },
    },
    "& h6": {
      fontFamily: "'Montserrat', sans-serif",
      fontSize: "18px",
      fontWeight: "600",
      lineHeight: "21px",
      color: colors.doyarPeach,
      marginBottom: "0px",
    },
    "& h5": {
      fontFamily: "'Montserrat', sans-serif",
      fontSize: "36px",
      fontWeight: "600",
      lineHeight: "64px",
      color: "#181817",
      marginBottom: "46px",

      [theme.breakpoints.down("md")]: {
        fontSize: "24px",
        lineHeight: "35px",
      },
    },
    "& p": {
      fontFamily: "'Montserrat', sans-serif",
      fontSize: "18px",
      fontWeight: "400",
      lineHeight: "34px",
      color: colors.secondary,
      margin: "46px 0px",
      textAlign: "center",
      maxWidth: "803px",

      [theme.breakpoints.down("md")]: {
        fontSize: "16px",
        lineHeight: "30px",
      },
    },
  },
  "@keyframes fadeInOut": {
    "0%": {
      opacity: 0.3,
      transform: "translateY(30%)", // Start from below the container
    },
    "100%": {
      opacity: 1,
      transform: "translateY(0)", // End at the original position
    },
  },
}));
