import React from "react";
import { Link } from "react-router-dom";
import { useStyles } from "./buttons.style";

const ButtonP = (props) => {
  const { fontColor, bgColor, borderColor, to, a } = props;
  const classes = useStyles();
  return a ? (
    <a
      className={classes.container + " primary"}
      style={{
        color: fontColor,
        backgroundColor: bgColor,
        borderColor: borderColor,
      }}
      href={to}
      target="_blank"
      rel="noreferrer"
    >
      {props.children}
    </a>
  ) : (
    <Link
      className={classes.container + " primary"}
      style={{
        color: fontColor,
        backgroundColor: bgColor,
        borderColor: borderColor,
      }}
      to={to}
    >
      {props.children}
    </Link>
  );
};

export default ButtonP;
