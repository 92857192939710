import React, { useEffect, useState } from "react";
import { useStyles } from "./style";
import { RocketIcon } from "../../../assets/icons";

const Perks = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % 6);
    }, 5000); // Adjust the interval time (in milliseconds) as needed

    return () => clearInterval(interval);
  }, [currentSlide]);
  const classes = useStyles();
  return (
    <div className={classes.container} id="benefits">
      <div className="wrapper">
        <h1>Perks and Benefits</h1>
        <p className="intro">
          With a wide range of benefits that comes with being an ambassador with
          us. Here are just a few
        </p>
        <div className="cards">
          <div className={currentSlide === 0 ? "card active" : "card"}>
            <div className="header">
              <h6>1. Advocacy and Impact</h6> <RocketIcon />
            </div>
            <p>
              An opportunity play a critical role in advocating ethical banking
              practices, making a positive impact on your peers financial
              behaviour.
            </p>
          </div>
          <div className={currentSlide === 1 ? "card active" : "card"}>
            <div className="header">
              <h6>2. Skill Development </h6> <RocketIcon />
            </div>
            <p>
              We equip you with marketing, communication, technology, event
              management and other skills valuable for your personal and
              professional growth.
            </p>
          </div>
          <div className={currentSlide === 2 ? "card active" : "card"}>
            <div className="header">
              <h6>3. Resume Enhancement </h6> <RocketIcon />
            </div>
            <p>
              Get a chance to enhances your resume, showcasing your commitment
              to financial ethics and ability to represent a fintech company.
            </p>
          </div>
          <div className={currentSlide === 3 ? "card active" : "card"}>
            <div className="header">
              <h6>4. Professional Growth </h6> <RocketIcon />
            </div>
            <p>
              Enjoy mentorship and access to professional development
              opportunities in fintech and other tech related field.
            </p>
          </div>
          <div className={currentSlide === 4 ? "card active" : "card"}>
            <div className="header">
              <h6>5. Networking </h6> <RocketIcon />
            </div>
            <p>
              Opportunity to build relationships with industry professionals and
              like-minded peers, enriching your knowledge of finance and
              technology.
            </p>
          </div>
          <div className={currentSlide === 5 ? "card active" : "card"}>
            <div className="header">
              <h6>6. Rewards & Internship Oppurtunitie </h6> <RocketIcon />
            </div>
            <p>
              Earn financial incentives or rewards from us and also stand a
              chance for paid internship with us.
            </p>
          </div>
        </div>
        <div className="dots">
          <div
            className={currentSlide === 0 ? "dot active" : "dot"}
            onClick={() => setCurrentSlide(0)}
          ></div>
          <div
            className={currentSlide === 1 ? "dot active" : "dot"}
            onClick={() => setCurrentSlide(1)}
          ></div>
          <div
            className={currentSlide === 2 ? "dot active" : "dot"}
            onClick={() => setCurrentSlide(2)}
          ></div>
          <div
            className={currentSlide === 3 ? "dot active" : "dot"}
            onClick={() => setCurrentSlide(3)}
          ></div>
          <div
            className={currentSlide === 4 ? "dot active" : "dot"}
            onClick={() => setCurrentSlide(4)}
          ></div>
          <div
            className={currentSlide === 5 ? "dot active" : "dot"}
            onClick={() => setCurrentSlide(5)}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Perks;
