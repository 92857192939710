import React from "react";
import { useStyles } from "./style";
import heroImg from "../../../assets/savings/hero-img.webp";
import ButtonS from "../../buttons/secondary";
import { colors } from "../../../styles/colors";

const Hero = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className="wrapper">
        <div className="left">
          <h6
            data-aos="zoom-in"
            data-aos-easing="linear"
            data-aos-duration="1000"
          >
            Savings Plan
          </h6>
          <h1
            data-aos="fade-right"
            data-aos-easing="linear"
            data-aos-duration="1000"
          >
            Savings that Align with your Values
          </h1>
          <p
            data-aos="zoom-in"
            data-aos-easing="linear"
            data-aos-duration="1000"
          >
            Our ethical savings plans are rooted on the principal of
            non-interest. Your savings are invested in ethical, sustainable and
            secured financial instrument that guarantees a high profit.
          </p>
          {/* <ButtonS
            fontColor={"#fff"}
            bgColor={colors.doyarDark}
            dataAos="zoom-in"
            dataAosEasing="linear"
            dataAosDuration="1000"
          >
            Start Saving Today
          </ButtonS> */}
        </div>
        <img
          className="desktop"
          src={heroImg}
          alt="hero-img"
          height={"509px"}
          data-aos="fade-left"
          data-aos-easing="linear"
          data-aos-duration="1000"
        />
        <img
          className="mobile"
          src={heroImg}
          alt="hero-img"
          height={"509px"}
          data-aos="zoom-in"
          data-aos-easing="linear"
          data-aos-duration="1000"
        />
      </div>
    </div>
  );
};

export default Hero;
