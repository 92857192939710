import React from "react";
import { useStyles } from "./style";
import heroImg from "../../../assets/terms/hero-img.webp";
import ButtonS from "../../buttons/secondary";
import { colors } from "../../../styles/colors";

const Hero = () => {
  const classes = useStyles();
  return <div className={classes.container + " wrapper"}></div>;
};

export default Hero;
