import React, { useEffect, useState } from "react";
import { useStyles } from "./community.style";
import slide1 from "../../../assets/homepage/slide-1.webp";
import slide2 from "../../../assets/homepage/slide-2.webp";
import slide3 from "../../../assets/homepage/slide-3.webp";
import imageDots from "../../../assets/homepage/dots.webp";
import ButtonP from "../../buttons/primary";
import ButtonS from "../../buttons/secondary";

const Community = () => {
  const classes = useStyles();
  const [currentImage, setCurrentImage] = useState(0);
  const [currentText, setCurrentText] = useState(0);

  const images = [slide1, slide2, slide3];
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 5000); // Adjust the interval time (in milliseconds) as needed

    return () => clearInterval(interval);
  }, [images]);
  const texts = ["Financial Stability", "Accessible to Everyone"];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentText((prevText) => (prevText + 1) % texts.length);
    }, 5000); // Adjust the interval time (in milliseconds) as needed

    return () => clearInterval(interval);
  }, [texts]);

  return (
    <div className={classes.container + " wrapper"}>
      <div className="grid">
        <div className="slide_show">
          <div className="slides">
            {images.map((image, index) => (
              <img
                key={index}
                className={"slide " + (index === currentImage ? "active" : "")}
                src={image}
                alt={`Slide ${index}`}
                style={{ opacity: index === currentImage ? 1 : 0 }}
              />
            ))}

            <img
              src={imageDots}
              alt="dots"
              className="dots-img"
              height={"94px"}
              width={"94px"}
            />
          </div>
          <div className="dots">
            {images.map((image, index) => (
              <div
                key={index}
                className={"dot " + (index === currentImage ? "active" : "")}
                onClick={() => setCurrentImage(index)}
              ></div>
            ))}
          </div>
        </div>
        <div className="contents">
          <h6>Be a part of our community</h6>
          <p>
            Doyar community is a space where individuals and businesses come
            together to share experiences, build wealth and get support on the
            benefits of non-interest Banking.
          </p>
          <div className="btns">
            <ButtonP to="https://forms.gle/zBLcw5JDxmn3Zt9r7" a={true} >
              Student Ambassador Program Waitlist
            </ButtonP>
            <ButtonS to="/community">Explore Benefits</ButtonS>
          </div>
        </div>
      </div>
      <div className="bottom">
        {texts.map((text, index) => (
          <h6
            key={index}
            className={
              "text-" + index + " " + (index === currentText ? "active" : "")
            }
          >
            {text}
          </h6>
        ))}
      </div>
    </div>
  );
};

export default Community;
