import { makeStyles } from "@mui/styles";
import { colors } from "../../../styles/colors";
import bg from "../../../assets/savings/zigzag-bg.svg";

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: "100px 0px",
    // background: `#fff`,
    width: "100%",
    maxWidth: "2500px",
    [theme.breakpoints.down("sm")]: {
      // width: "100%",
      overflowX: "hidden",
    },

    "& .wrapper": {
      "& h1": {
        fontWeight: 700,
        fontSize: "36px",
        lineHeight: "50px",
        color: "#181817",
        textAlign: "center",
        "& span": {
          color: colors.doyarPeach,
        },

        [theme.breakpoints.down("sm")]: {
          fontSize: "30px",
        },
      },
      "& p": {
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "28px",
        color: "#30415A",
        marginBottom: "166px",
        textAlign: "center",
        [theme.breakpoints.down("sm")]: {
          fontSize: "14px",
          lineHeight: "26px",
          marginBottom: "20px",
        },
      },
    },
    "& .running-texts": {
      display: "flex",
      gap: "60px",
      marginBottom: "70px",
      overflow: "hidden",
      position: "relative",
      width: "2500px",
      [theme.breakpoints.down("sm")]: {
        gap: "20px",
        marginBottom: "40px",
        width: "650px",
      },
      "&.one": {
        animation: "$marquee1 30s linear infinite",
      },
      "&.two": {
        animation: "$marquee2 30s linear infinite",
      },
      "&.three": {
        animation: "$marquee3 35s linear infinite",
      },
      "&.four": {
        animation: "$marquee4 30s linear infinite",
      },

      "& h6": {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: "56px",
        fontWeight: "black",
        lineHeight: "50px",
        color: colors.doyarDark,
        whiteSpace: "nowrap",
        "&.outline": {
          color: "white",
          textShadow:
            "1px 1px 0 #1B2D4A, -1px 1px 0 #1B2D4A, -1px -1px 0 #1B2D4A, 1px -1px 0 #1B2D4A",
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: "14px",
          lineHeight: "20px",
        },
      },
    },
  },
  "@keyframes marquee1": {
    from: {
      transform: " translateX(10%)",
    },
    to: {
      transform: " translateX(-100%)",
    },
  },
  "@keyframes marquee2": {
    from: {
      transform: " translateX(30%)",
    },
    to: {
      transform: " translateX(-120%)",
    },
  },
  "@keyframes marquee3": {
    from: {
      transform: " translateX(60%)",
    },
    to: {
      transform: " translateX(-140%)",
    },
  },
  "@keyframes marquee4": {
    from: {
      transform: " translateX(40%)",
    },
    to: {
      transform: " translateX(-160%)",
    },
  },
}));
