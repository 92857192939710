import { makeStyles } from "@mui/styles";
import { colors } from "../../../styles/colors";
import bg from "../../../assets/savings/savings-card-img.jpg";

export const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: "83px",
    paddingBottom: "24px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "30px",
    },
    "& .wrapper": {
      fontFamily: "'Montserrat', sans-serif",
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gap: "53px",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        gridTemplateColumns: "1fr",
        gap: "30px",
      },
      "& h1": {
        fontWeight: 700,
        fontSize: "36px",
        lineHeight: "50px",
        color: "#181817",
        marginBottom: "22px",
        [theme.breakpoints.down("sm")]: {
          textAlign: "center",
          fontSize: "30px",
        },
        "& span": {
          color: colors.doyarPeach,
        },
      },
      "& p": {
        fontWeight: 400,
        fontSize: "18px",
        lineHeight: "34px",
        color: colors.doyarDark,
        marginBottom: "22px",

        [theme.breakpoints.down("sm")]: {
          fontSize: "16px",
          lineHeight: "32px",
          textAlign: "center",
        },
      },
      "& .left": {
        position: "relative",
        "&.mobile": {
          display: "none",
          [theme.breakpoints.down("md")]: {
            display: "block",
          },
        },
        "&.desktop": {
          display: "block",
          [theme.breakpoints.down("md")]: {
            display: "none",
          },
        },
        "& .left__img_container": {
          overflow: "hidden",
          position: "absolute",
          height: "499px",
          width: "464px",
          top: "-32px",
          left: "0",
          borderRadius: "20px",
          [theme.breakpoints.down("md")]: {
            width: "90%",
            right: "5%",
          },

          "& .left__img": {
            width: "100%",
            height: "100%",
            backgroundColor: "#fff",
            backgroundImage: `url(${bg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            "&:hover,:focus": {
              transition: "all .4s ease-in-out",
              transform: "scale(1.1)",
            },
          },
        },
        "& .left__shadow": {
          background: "#F4F6F9",
          borderRadius: "20px",
          height: "499px",
          width: "464px",
          marginLeft: "32px",
          // marginTop: "-32px",
          [theme.breakpoints.down("md")]: {
            width: "90%",
            marginLeft: "5%",
          },
        },
      },
    },
  },
}));
