import { makeStyles } from "@mui/styles";
import { colors } from "../../../styles/colors";
import bg from "../../../assets/community/hero-bg.svg";

export const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: "58px",
    background: `url(${bg}) no-repeat center`,
    backgroundSize: "cover",
    fontFamily: "'Montserrat', sans-serif",

    "& .wrapper": {
      display: "grid",
      gridTemplateColumns: ".8fr 1.2fr",
      paddingTop: "130px",
      gap: "16px",
      [theme.breakpoints.down("md")]: {
        paddingTop: "60px",
        gridTemplateColumns: "1fr",
      },

      "& h1": {
        color: "#181817",
        fontSize: "36px",
        fontWeight: 700,
        lineHeight: "50px",
        marginBottom: "16px",
        // maxWidth: "302px",
        [theme.breakpoints.down("md")]: {
          textAlign: "center",
        },

        [theme.breakpoints.down("sm")]: {
          fontSize: "30px",
        },
      },
      "& p": {
        color: "#30415A",
        fontSize: "18px",
        fontWeight: 400,
        lineHeight: "34px",
        marginBottom: "36px",
        [theme.breakpoints.down("md")]: {
          textAlign: "center",
        },

        [theme.breakpoints.down("sm")]: {
          fontSize: "16px",
        },
      },
      "& .counters": {
        marginTop: "30px",
        display: "flex",
        gap: "66px",
        "& .counter": {
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          [theme.breakpoints.down("sm")]: {
            alignItems: "center",
          },
          "& h6": {
            fontFamily: "'Inter', sans-serif",
            color: "#181817",
            fontSize: "30px",
            fontWeight: 600,
            lineHeight: "36px",
            "&.yellow": {
              color: "#E39F08",
            },
            [theme.breakpoints.down("sm")]: {
              fontSize: "20px",
            },
          },
          "& p": {
            fontFamily: "'Inter', sans-serif",
            color: "#30415A",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "28px",
          },
        },
      },
      "& .left": {
        [theme.breakpoints.down("md")]: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          "& a": {
            display: "none",
          },
        },
      },
      "& .right": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",

        [theme.breakpoints.down("sm")]: {
          "& img": {
            height: "auto",
            width: "80%",
            alignSelf: "center !important",
          },
        },
        "& .slide": {
          position: "relative",
          display: "none",
          animation: "$fadeInOut .5s ease-in-out",

          "&.active": {
            display: "flex",
            justifyContent: "center",
          },

          "& .card": {
            position: "absolute",
            display: "flex",
            alignItems: "center",
            gap: "7px",
            padding: "10px",
            borderRadius: "10px",
            background: "#fff",
            animation: "$upDownAnimation 2s infinite alternate",
            [theme.breakpoints.down("sm")]: {
              padding: "7px",
              borderRadius: "5px",
            },

            "& span": {
              fontFamily: "'Inter', sans-serif",
              color: "#30415A",
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "21px",
              [theme.breakpoints.down("sm")]: {
                fontSize: "6px",
                lineHeight: "11px",
              },
            },
          },

          "&.slide1": {
            "& .card.card1": {
              top: "48px",
              left: "-100px",
              [theme.breakpoints.down("sm")]: {
                left: "0",
              },
            },
            "& .card.card2": {
              bottom: "38px",
              right: "-70px",
              [theme.breakpoints.down("sm")]: {
                right: "0",
              },
            },
          },
          "&.slide2": {
            "& .card": {
              top: "48px",
              right: "-120px",
              [theme.breakpoints.down("sm")]: {
                right: "0",
              },
            },
          },
          "&.slide3": {
            "& .card": {
              bottom: "38px",
              left: "-100px",
              [theme.breakpoints.down("sm")]: {
                left: "0",
              },
            },
          },
          "& img": {
            alignSelf: "center",
          },

          // "& .slide1__img": {
          //   background: `url(${slide1})`,
          //   backgroundSize: "cover",
          //   backgroundPosition: "center",
          //   backgroundRepeat: "no-repeat",
          //   borderRadius: "20px",
          //   height: "436px",
          //   width: "457.75px",
          //   alignSelf: "center",
          // },
        },
      },
    },
  },
  "@keyframes upDownAnimation": {
    "0%": {
      transform: "translateY(0)",
    },
    "100%": {
      transform: "translateY(-10px)",
    },
  },
  "@keyframes fadeInOut": {
    "0%": {
      opacity: 0.3,
    },
    "100%": {
      opacity: 1,
    },
  },
}));
